import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {Dimension} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';

export const toggleDrawerHandler = (
    mesh: THREE.Group<THREE.Object3DEventMap>,
    variables: TemplateVariable,
    exteriorThickness: number,
    isInnerDrawer: boolean,
    isDrawerBottom = false,
    isDrawerBack = false,
    offset = -100,
    insert: Dimension,
    isInsetDrawer = false,
    noDepthAddition = false
): AnimationHandler => {
    const depth = insert.y;
    const depthAddition =
        (isInnerDrawer || isInsetDrawer) && !noDepthAddition
            ? exteriorThickness
            : 0;
    const depthOffsetCalculation = variables?.isBinProduct ? 400 : offset;

    const initialPosition = {
        y: isDrawerBottom || isDrawerBack ? depth : depthAddition,
    };
    const openPosition = {
        y:
            isDrawerBottom || isDrawerBack
                ? depth -
                  depthOffsetCalculation -
                  (isInsetDrawer ? exteriorThickness : 0)
                : -depthOffsetCalculation,
    };
    const closePosition = {
        y:
            (isDrawerBottom || isDrawerBack
                ? depth + depthAddition
                : depthAddition) - 2,
    };

    const openDrawer = (animationSpeed = 600, delay = false) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .delay(isInnerDrawer && delay ? 400 : 0)
                .onUpdate(() => {
                    mesh.position.y = initialPosition.y;
                });

            tween.start();
        });

    const closeTween = (animationSpeed = 600) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialPosition)
                .to(closePosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.position.y = initialPosition.y;
                });

            tween.start();
        });

    return {
        open: [openDrawer],
        close: [closeTween],
    };
};
