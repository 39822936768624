import React from 'react';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import useDynamicValues from 'components/customer/Preview3D/values/useDynamicValues';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {DrawerValues} from 'components/customer/Preview3D/values/useDrawerDynamicValues';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {ConditionalRender} from 'Preview3D/types';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {MaterialType} from 'components/customer/Materials/entity';
import {reduceMaterialOpacity} from 'components/customer/Preview3D/helpers/Three';
import {getBaseInnerDrawerFaceHeight} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

const useInnerDrawer = (
    scene: React.MutableRefObject<THREE.Scene>,
    toggleInnerDrawer: (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        innerDrawerYPos: number,
        depthOffsetCalculation: number
    ) => void,
    dynamicValues: ReturnType<typeof useDynamicValues>['dynamicValues'],
    showTexture?: boolean
) => {
    const {values, productDataStore} = use3DContext();
    const innerDrawers = dynamicValues.innerDrawers as {
        drawers: DrawerValues[];
    };
    const horizontalGrainCarc = Boolean(values.hor_grain_carc);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );
    const isPantry = variables?.shelfType === 'PANTRY';
    const {carcaseTexture, carcaseEdgeTexture} = useMaterialTexture();
    const selectedCarcaseMaterial = useAppSelector(
        (state) => getMaterial(state, MaterialType.CARCASE),
        shallowEqual
    );
    const innerDrawerFaceHeight = useAppSelector(getBaseInnerDrawerFaceHeight);
    const nonSupplyCarcase = selectedCarcaseMaterial?.brand_id === 23;
    const includeHardware = Boolean(values.cabinet_include_hardware);

    const addInnerDrawers = (
        model: THREE.Group<THREE.Object3DEventMap>,
        drawerCount = 2
    ) => {
        const zScaleThreshold = values.cabinet_depth < 560;
        const degreesX = 90;
        const rotationX = (degreesX * Math.PI) / 180;

        for (let index = 0; index < drawerCount; index++) {
            let drawerHeight = index === 0 && isPantry ? 110 : 200;
            if (innerDrawerFaceHeight !== 0)
                drawerHeight = innerDrawerFaceHeight;
            const depthOffsetCalculation = 50 * (index + 1);
            const innerDrawer = model.clone();
            const innerDrawerYPos =
                -(values.cabinet_depth / 2) -
                85 +
                (zScaleThreshold ? (560 - values.cabinet_depth) * 0.154 : 0);
            const zScale = zScaleThreshold
                ? values.cabinet_depth / 2 / 0.28
                : 1000;

            innerDrawer.scale.set(
                values.cabinet_width * 1.8 - 10,
                5.25 * drawerHeight,
                zScale
            );

            innerDrawer.rotation.x = rotationX;
            innerDrawer.position.x =
                -values.cabinet_width + values.cabinet_width * 1.05 * 0.24;
            innerDrawer.position.z =
                -31.5 + innerDrawers.drawers[Number(index)].position;
            innerDrawer.position.y = innerDrawerYPos;

            innerDrawer.traverse((child: THREE.Mesh) => {
                if (child.isMesh) {
                    (child.material as THREE.Material).needsUpdate = true;
                    if (horizontalGrainCarc) {
                        carcaseTexture.rotation = (Math.PI / 4) * 2;
                        carcaseTexture.center = new THREE.Vector2(0.5, 0.5);
                        carcaseTexture.needsUpdate = true;
                    }
                    const material = new THREE.MeshStandardMaterial({
                        map: carcaseTexture,
                    });

                    if (child.name?.endsWith('Edge')) {
                        child.material = new THREE.MeshStandardMaterial({
                            map: carcaseEdgeTexture,
                        });
                    } else {
                        if (
                            ['Component54', 'Component55'].includes(child.name)
                        ) {
                            child.material = material;
                        } else {
                            child.material = new THREE.MeshBasicMaterial({
                                color: '#F7F8F5',
                            });
                        }
                    }

                    if (!showTexture) {
                        reduceMaterialOpacity(child.material, 0);
                    }

                    if (nonSupplyCarcase) {
                        reduceMaterialOpacity(child.material);
                    }

                    const hideWhenNoHardware =
                        !['Component55', 'Component54'].includes(child.name) &&
                        !includeHardware;

                    if (hideWhenNoHardware) {
                        reduceMaterialOpacity(child.material);
                    }

                    if (child.name !== 'Component55') {
                        const edges = new THREE.EdgesGeometry(child.geometry);

                        const lineMaterial = new THREE.LineBasicMaterial({
                            color: '#333',
                        });

                        if (nonSupplyCarcase || hideWhenNoHardware) {
                            reduceMaterialOpacity(lineMaterial);
                        }

                        const lineSegments = new THREE.LineSegments(
                            edges,
                            lineMaterial
                        );

                        child.add(lineSegments);
                    }
                }
            });

            scene.current.add(innerDrawer);
            toggleInnerDrawer(
                innerDrawer,
                innerDrawerYPos,
                depthOffsetCalculation >= 200 ? 200 : depthOffsetCalculation
            );
        }
    };

    const applyDynamicInnerDrawers = () => {
        const loader = new GLTFLoader();

        loader.load('/templates/3D/models/inner_drawer.glb', function (gltf) {
            const model = gltf.scene;

            addInnerDrawers(model, variables.innerDrawerCount);
        });
    };

    const innerDrawerParts: ConditionalRender[] = [
        {
            // Temporary disable the rendering of inner drawer 3D model
            condition:
                Boolean(innerDrawers?.drawers) &&
                Boolean(variables.innerDrawerCount) &&
                false,
            callback: applyDynamicInnerDrawers,
        },
    ];

    return {
        innerDrawerParts,
    };
};

export default useInnerDrawer;
