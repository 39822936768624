import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {PreviewFormValues} from 'Preview3D/types';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';

export const toggleCornerHandler = (
    mesh: THREE.Group<THREE.Object3DEventMap>,
    exteriorThickness: number,
    values: PreviewFormValues,
    isRightDoor = false
): AnimationHandler => {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    if (isRightDoor) {
        mesh.position.z =
            -(values.cabinet_left_width - values.cabinet_right_depth) -
            exteriorThickness;
        mesh.position.x =
            values.cabinet_right_width - values.cabinet_left_depth;
        mesh.scale.x = -1;
    }

    const degrees = 90;
    const radians = (degrees * Math.PI) / 180;

    const initialRotation = {y: (45 * Math.PI) / 180};
    const openRotation = {
        y: isRightDoor ? (170 * Math.PI) / 180 : -radians,
    };
    const closeRotation = {y: (45 * Math.PI) / 180};

    const openDrawer = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        });

    const closeDrawer = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const close = new TWEEN.Tween(initialRotation)
                .to(closeRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            close.start();
        });

    return {
        open: [openDrawer],
        close: [closeDrawer],
    };
};
