import {useCallback} from 'react';
import {ConditionalValues} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';
import {
    generateDoorPosition as generateDoor,
    getDoorFormValues,
    generateReturnDoor as getReturnDoor,
    generateApplianceDoor as getApplianceDoor,
    generateDoorDrawer,
} from 'components/customer/Preview3DCommon/values/door';

const useDoorDynamicValues = () => {
    const {values, productDataStore} = use3DContext();

    const hasDoor = typeof values.cabinet_door_gap !== 'undefined';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const {carcaseThickness, exteriorThickness} = useMaterialTexture();

    const formValues = getDoorFormValues(values);

    const generateDoorPosition = useCallback(() => {
        return generateDoor(
            variables,
            {
                ...values,
                cabinet_carc_thickness: carcaseThickness,
                cabinet_ext_thickness: exteriorThickness,
            },
            formValues
        );
    }, [formValues]);

    const generateDoorPositionWithDrawerTop = useCallback(() => {
        return generateDoorDrawer(formValues);
    }, [formValues]);

    const generateReturnDoor = useCallback(() => {
        return getReturnDoor(
            variables,
            {
                ...values,
                cabinet_carc_thickness: carcaseThickness,
                cabinet_ext_thickness: exteriorThickness,
            },
            formValues
        );
    }, [formValues]);

    const generateApplianceDoor = useCallback(() => {
        return getApplianceDoor(
            variables,
            {
                ...values,
                cabinet_carc_thickness: carcaseThickness,
                cabinet_ext_thickness: exteriorThickness,
            },
            formValues
        );
    }, [formValues]);

    const doorValues: ConditionalValues[] = [
        {
            key: 'doorDefault',
            condition: hasDoor,
            callback: generateDoorPosition,
        },
        {
            key: 'doorDrawer',
            condition: formValues.drawers && hasDoor,
            callback: generateDoorPositionWithDrawerTop,
        },
        {
            key: 'doorWithReturn',
            condition: variables.isReturnProduct,
            callback: generateReturnDoor,
        },
        {
            key: 'applianceDoor',
            condition:
                variables.isApplianceBifoldProduct ||
                Boolean(
                    variables?.fields.find(
                        (field) => field.name === 'isApplianceRollUp'
                    )?.expression
                ),
            callback: generateApplianceDoor,
        },
    ];

    return {
        doorValues,
    };
};

export default useDoorDynamicValues;
