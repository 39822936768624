import {useCallback} from 'react';
import {useAppSelector} from 'store/customer';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {ConditionalValues, Drawer} from 'Preview3D/types';
import {getBaseInnerDrawerFaceHeight} from 'components/customer/Preview3DCommon/store/viewerSlice';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {
    generateDrawers as getDrawers,
    getDrawerFormValues,
    generateInnerDrawer as getInnerDrawers,
} from 'components/customer/Preview3DCommon/values/drawer';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

export type DrawerValues = {
    faceHeight: number;
    depth: number;
    position: number;
    bottomPosition: number;
    bottomYPosition: number;
    drawerBottomYPosition: number;
    bottomDepth: number;
    depthOffset: number;
    index: number;
    drawerBackHeight: number;
    isInsetDrawer: boolean;
};

const useDrawerDynamicValues = () => {
    const {values, productDataStore} = use3DContext();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const innerDrawerFaceHeight = useAppSelector(getBaseInnerDrawerFaceHeight);
    const {exteriorThickness, carcaseThickness} = useMaterialTexture();

    const formValues = getDrawerFormValues(values);

    const generateDrawerPosition = useCallback(() => {
        return generateDrawers(
            formValues.drawers,
            false,
            variables?.isWallOvenProduct
                ? Number(values.cabinet_total_drawer_height)
                : formValues.height
        );
    }, [formValues]);

    const generateDrawers = useCallback(
        (
            drawers: Drawer[],
            isInnerDrawer = false,
            containerHeight = formValues.height,
            containerWidth = formValues.width
        ) => {
            return getDrawers(
                drawers,
                formValues,
                variables,
                {
                    ...values,
                    cabinet_carc_thickness: carcaseThickness,
                    cabinet_ext_thickness: exteriorThickness,
                },
                isInnerDrawer,
                containerHeight,
                containerWidth,
                formValues.hasDoor
            );
        },
        [formValues]
    );

    const generateInnerDrawer = () => {
        return getInnerDrawers(
            {
                ...values,
                cabinet_carc_thickness: carcaseThickness,
                cabinet_ext_thickness: exteriorThickness,
            },
            variables,
            formValues,
            innerDrawerFaceHeight
        );
    };

    const generateDrawerFace = () => {
        if (!formValues.drawerFaceHeights) return;

        const drawers = formValues.drawerFaceHeights.map((faceHeight) => ({
            drawer_face_height: faceHeight,
            drawer_runner_specs: `199x500 : White D`,
            drawer_type: 1,
        }));

        const result = generateDrawers(
            drawers,
            true,
            values.cabinet_panel_length,
            values.cabinet_panel_width
        );

        return result;
    };

    const drawerValues: ConditionalValues[] = [
        {
            key: 'drawerDefault',
            condition: Boolean(formValues.drawers),
            callback: generateDrawerPosition,
        },
        {
            key: 'innerDrawers',
            condition: Boolean(variables.innerDrawerCount),
            callback: generateInnerDrawer,
        },
        {
            key: 'drawerFace',
            condition: Boolean(formValues.drawerFaceHeights),
            callback: generateDrawerFace,
        },
    ];

    return {
        drawerValues,
    };
};

export default useDrawerDynamicValues;
