import {useCallback} from 'react';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {ConditionalValues} from 'Preview3D/types';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {
    getRangehoodFormValues,
    generateRangehoodValues as getRangehoodValues,
} from 'components/customer/Preview3DCommon/values/rangehood';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

export const DEFAULT_VENT_WIDTH = 176.75;
export const DEFAULT_VENT_FRONT_WIDTH = 203;

const useRangehoodDynamicValues = () => {
    const {values, productDataStore} = use3DContext();
    const {carcaseThickness} = useMaterialTexture();

    const formValues = getRangehoodFormValues(values);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const generateRangehoodValues = useCallback(() => {
        return getRangehoodValues(
            {
                ...values,
                cabinet_carc_thickness: carcaseThickness,
            },
            formValues
        );
    }, [formValues]);

    const rangehoodValues: ConditionalValues[] = [
        {
            key: 'rangehood',
            condition:
                variables?.hasRangehoodVent ||
                typeof values.rangehood_opening_height !== 'undefined',
            callback: generateRangehoodValues,
        },
    ];

    return {
        rangehoodValues,
    };
};

export default useRangehoodDynamicValues;
