import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';

export const toggleInnerDrawerHandler = (
    mesh: THREE.Group<THREE.Object3DEventMap>,
    innerDrawerYPos: number,
    isInnerDrawer: boolean,
    depthOffsetCalculation = 50
): AnimationHandler => {
    const initialPosition = {
        y: innerDrawerYPos,
    };
    const openPosition = {
        y: innerDrawerYPos - depthOffsetCalculation,
    };
    const closePosition = {
        y: innerDrawerYPos,
    };

    const openDrawer = (animationSpeed = 600, delay?: boolean) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .delay(isInnerDrawer && delay ? 400 : 0)
                .onUpdate(() => {
                    mesh.position.y = initialPosition.y;
                });

            openTween.start();
        });

    const closeTween = (animationSpeed = 600) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialPosition)
                .to(closePosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.position.y = initialPosition.y;
                });

            tween.start();
        });

    return {
        open: [openDrawer],
        close: [closeTween],
    };
};
