import {useCallback, useMemo, useRef, useState} from 'react';
import * as THREE from 'three';
import {OperationGroup, Dimension} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {Animation} from 'components/customer/Preview3D/animation/entity/Animation';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';
import {toggleDoorHandler} from 'components/customer/Preview3D/animation/doorAnimationHandler';
import {toggleCornerHandler} from 'components/customer/Preview3D/animation/cornerAnimationHandler';
import {toggleDrawerHandler} from 'components/customer/Preview3D/animation/drawerAnimationHandler';
import {toggleApplianceExtendedDoorHandler} from 'components/customer/Preview3D/animation/applianceAnimationHandler';
import {toggleMainBifoldDoorHandler} from 'components/customer/Preview3D/animation/mainBifoldDoorAnimationHandler';
import {toggleExtendedBifoldDoorHandler} from 'components/customer/Preview3D/animation/extendedBifoldDoorAnimationHandler';
import {toggleInnerDrawerHandler} from 'components/customer/Preview3D/animation/innerDrawerAnimtionHandler';
import {useSurveyButton} from 'shared/components/Surveys/helpers/useSurveyButton';
import {SURVEYS} from 'shared/components/Surveys/helpers/useSurveys';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

enum AnimationType {
    DOOR,
    DRAWER,
}
interface AnimationEvents {
    animation: Animation;
    type: AnimationType;
}

const useDoorAndDrawerAnimation = (
    isDoorOpen: boolean,
    hidden: boolean,
    raw: boolean
) => {
    const isDoorOpenRef = useRef(isDoorOpen);

    const openAnimations = useRef<AnimationEvents[]>([]);
    const closeAnimations = useRef<AnimationEvents[]>([]);

    const [prefix, setPrefix] = useState('Close');

    const {exteriorThickness, carcaseThickness} = useMaterialTexture();
    const {showSurveyForm} = useSurveyButton(SURVEYS.ProductVisualizer3d, true);

    const {values, productDataStore} = use3DContext();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );
    const isInnerDrawer = Boolean(variables.innerDrawerCount);
    const hasInsetDrawer = Boolean(variables.hasInsetDrawer);

    const isMicrowaveDrawer = useMemo(() => {
        return (
            typeof values.microwave_opening_height !== 'undefined' &&
            !variables?.isWallOvenProduct
        );
    }, [variables, values]);

    const buttonLabel = useMemo(() => {
        let product = 'Doors';

        if (values.drawers) {
            product = 'Drawers';
            if (values.shelves) {
                product = 'Doors and Drawers';
            }
        }
        return `${prefix} ${product}`;
    }, [values, prefix]);

    const handleClick = useCallback(() => {
        showSurveyForm();
        const isOpen = isDoorOpenRef.current;
        const animations = isOpen
            ? closeAnimations.current
            : openAnimations.current;

        if (animations.length) {
            Promise.all(
                animations.map(({animation}) => animation(undefined, !isOpen))
            )
                .then(() => {
                    isDoorOpenRef.current = !isOpen;
                    setPrefix(isOpen ? 'Open' : 'Close');
                })
                .catch(() => {
                    // handle error here
                    // prolly log stuff when that happens
                });
        }
    }, [showSurveyForm]);

    const setAnimationHandlers = useCallback(
        ({open, close}: AnimationHandler, type = AnimationType.DOOR) => {
            // do not open drawers if it is hidden
            if (
                type == AnimationType.DOOR ||
                (type == AnimationType.DRAWER && !hidden)
            ) {
                if (isDoorOpenRef.current) {
                    open.forEach((animation) => {
                        void animation(0);
                    });
                }
            }

            openAnimations.current.push(
                ...open.map((animation) => ({
                    animation,
                    type,
                }))
            );

            closeAnimations.current.push(
                ...close.map((animation) => ({
                    animation,
                    type,
                }))
            );
        },
        [hidden]
    );

    const attachInnerDrawerEvent = useCallback(
        (
            mesh: THREE.Group<THREE.Object3DEventMap>,
            innerDrawerYPos: number,
            offset: number
        ) => {
            setAnimationHandlers(
                toggleInnerDrawerHandler(
                    mesh,
                    innerDrawerYPos,
                    isInnerDrawer,
                    offset
                ),
                AnimationType.DRAWER
            );
        },
        [isInnerDrawer]
    );

    const animationHandler = useCallback(
        (
            mesh: THREE.Group<THREE.Object3DEventMap>,
            operationGroup: OperationGroup,
            meshPos: Dimension,
            counter: number
        ) => {
            if (operationGroup.IsRightDoor) {
                setAnimationHandlers(
                    toggleDoorHandler(
                        mesh,
                        variables,
                        true,
                        operationGroup.IsPairDoor,
                        false,
                        hidden || raw ? 70 : undefined
                    )
                );
            }

            if (operationGroup.IsLeftDoor) {
                setAnimationHandlers(
                    toggleDoorHandler(
                        mesh,
                        variables,
                        false,
                        operationGroup.IsPairDoor
                    )
                );
            }

            if (operationGroup.IsTopHangDoor) {
                setAnimationHandlers(
                    toggleDoorHandler(mesh, variables, false, false, true)
                );
            }

            if (operationGroup.IsLeftApplianceMainDoor) {
                setAnimationHandlers(
                    toggleDoorHandler(mesh, variables, false, false, false, 87)
                );
            }

            if (operationGroup.IsRightApplianceMainDoor) {
                setAnimationHandlers(
                    toggleDoorHandler(mesh, variables, true, true, false, 87)
                );
            }

            if (
                operationGroup.IsCornerLeftDoor ||
                operationGroup.IsCornerRightDoor
            ) {
                setAnimationHandlers(
                    toggleCornerHandler(
                        mesh,
                        exteriorThickness,
                        values,
                        Boolean(operationGroup.IsCornerRightDoor)
                    )
                );
            }

            if (operationGroup.IsDrawerFront || operationGroup.IsDoorPullout) {
                const depthOffsetCalculation = 50 * (counter + 1);
                setAnimationHandlers(
                    toggleDrawerHandler(
                        mesh,
                        variables,
                        exteriorThickness,
                        isInnerDrawer,
                        false,
                        false,
                        operationGroup.IsDoorPullout
                            ? 250
                            : depthOffsetCalculation >= 200
                            ? 200
                            : depthOffsetCalculation,
                        meshPos,
                        hasInsetDrawer || isMicrowaveDrawer
                    ),
                    AnimationType.DRAWER
                );
            }

            if (operationGroup.IsDrawerBottom) {
                const depthOffsetCalculation = 50 * (counter + 1);
                setAnimationHandlers(
                    toggleDrawerHandler(
                        mesh,
                        variables,
                        exteriorThickness,
                        isInnerDrawer,
                        true,
                        false,
                        depthOffsetCalculation >= 200
                            ? 200
                            : depthOffsetCalculation,
                        meshPos,
                        hasInsetDrawer || isMicrowaveDrawer,
                        isMicrowaveDrawer
                    ),
                    AnimationType.DRAWER
                );
            }

            if (operationGroup.IsDrawerBack) {
                const depthOffsetCalculation = 50 * (counter + 1);
                setAnimationHandlers(
                    toggleDrawerHandler(
                        mesh,
                        variables,
                        exteriorThickness,
                        isInnerDrawer,
                        false,
                        true,
                        depthOffsetCalculation >= 200
                            ? 200
                            : depthOffsetCalculation,
                        meshPos,
                        hasInsetDrawer || isMicrowaveDrawer,
                        isMicrowaveDrawer
                    ),
                    AnimationType.DRAWER
                );
            }

            if (operationGroup.IsRightLShape) {
                if (operationGroup.IsBifoldRightDoor) {
                    setAnimationHandlers(
                        toggleMainBifoldDoorHandler(mesh, true)
                    );
                }

                if (operationGroup.IsBifoldLeftDoor) {
                    setAnimationHandlers(
                        toggleExtendedBifoldDoorHandler(
                            mesh,
                            meshPos,
                            variables,
                            values,
                            exteriorThickness,
                            carcaseThickness,
                            true,
                            Boolean(operationGroup.IsLeftBifoldExtension)
                        )
                    );
                }
            } else {
                if (operationGroup.IsBifoldLeftDoor) {
                    setAnimationHandlers(toggleMainBifoldDoorHandler(mesh));
                }

                if (operationGroup.IsBifoldRightDoor) {
                    setAnimationHandlers(
                        toggleExtendedBifoldDoorHandler(
                            mesh,
                            meshPos,
                            variables,
                            values,
                            exteriorThickness,
                            carcaseThickness
                        )
                    );
                }
            }

            if (operationGroup.IsLeftApplianceExtendedDoor) {
                setAnimationHandlers(
                    toggleApplianceExtendedDoorHandler(
                        mesh,
                        variables,
                        values,
                        carcaseThickness
                    )
                );
            }

            if (operationGroup.IsRightApplianceExtendedDoor) {
                setAnimationHandlers(
                    toggleApplianceExtendedDoorHandler(
                        mesh,
                        variables,
                        values,
                        carcaseThickness,
                        true
                    )
                );
            }
        },
        [
            variables,
            values,
            carcaseThickness,
            exteriorThickness,
            isInnerDrawer,
            hasInsetDrawer,
            isMicrowaveDrawer,
            hidden,
            raw,
        ]
    );

    const clearHandlers = useCallback(() => {
        openAnimations.current = [];
        closeAnimations.current = [];
    }, []);

    return {
        setButtonPrefix: setPrefix,
        buttonLabel,
        handleClick,
        animationHandler,
        isDoorOpenRef,
        clearHandlers,
        toggleInnerDrawerHandler: attachInnerDrawerEvent,
    };
};

export default useDoorAndDrawerAnimation;
