import {useCallback} from 'react';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {ConditionalValues} from 'Preview3D/types';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {
    generateWallOvenValues as getWallOvenValues,
    getWallOvenFormValues,
} from 'components/customer/Preview3DCommon/values/wallOven';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

export const DEFAULT_UPPER_PAIR_DEPTH = 400;

const useWallOvenDynamicValues = () => {
    const {values, productDataStore} = use3DContext();
    const {exteriorThickness} = useMaterialTexture();
    const formValues = getWallOvenFormValues(values);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const gemerateWallOvenValues = useCallback(() => {
        return getWallOvenValues(
            {
                ...values,
                cabinet_ext_thickness: exteriorThickness,
            },
            formValues
        );
    }, [formValues]);

    const wallOvenValues: ConditionalValues[] = [
        {
            key: 'wallOven',
            condition: variables?.isWallOvenProduct,
            callback: gemerateWallOvenValues,
        },
    ];

    return {
        wallOvenValues,
    };
};

export default useWallOvenDynamicValues;
