import React from 'react';
import {useProductItem} from 'hooks';
import {Formik} from 'formik';
import {useSearchParams} from 'react-router-dom';
import Preview3DComponent from 'components/customer/Preview3D/components/Preview3DComponent';

export const StandalonePreview3D = () => {
    const {initialValues, formSchema} = useProductItem();
    const [query] = useSearchParams();
    const view = query.get('view');

    if (
        !initialValues ||
        !formSchema ||
        Object.keys(formSchema).length == 0 ||
        Object.keys(initialValues).length == 0
    ) {
        return null;
    }

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={formSchema}
            initialValues={initialValues}
            onSubmit={undefined}>
            <form noValidate onSubmit={undefined}>
                <Preview3DComponent
                    previewLocation={'SPECS'}
                    fullScreen
                    raw={view?.toLowerCase() == 'raw'}
                />
            </form>
        </Formik>
    );
};

export default StandalonePreview3D;
