import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';

export const toggleMainBifoldDoorHandler = (
    mesh: THREE.Group<THREE.Object3DEventMap>,
    isRightLShape = false
): AnimationHandler => {
    let initialRotation = {y: (90 * Math.PI) / 180};
    let openRotation = {y: 0};
    let closeRotation = {y: (90 * Math.PI) / 180};

    if (isRightLShape) {
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const size = new THREE.Vector3();
        boundingBox.getSize(size);

        const width = size.x;

        mesh.scale.x = -1;
        mesh.position.x = width;

        initialRotation = {y: 0};
        openRotation = {y: (90 * Math.PI) / 180};
        closeRotation = {y: 0};
    }

    const openDrawer = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        });

    const closeTween = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialRotation)
                .to(closeRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            tween.start();
        });

    return {
        open: [openDrawer],
        close: [closeTween],
    };
};
