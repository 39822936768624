import {useCallback} from 'react';
import {ConditionalValues} from 'Preview3D/types';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {
    getMultipartFormValues,
    generateMultiPart as getMultiPart,
} from 'components/customer/Preview3DCommon/values/multipart';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

const useMultiPartDynamicValues = () => {
    const {values} = use3DContext();
    const {carcaseThickness, exteriorThickness} = useMaterialTexture();
    const formValues = getMultipartFormValues(values);

    const generateMultiPart = useCallback(() => {
        return getMultiPart(
            {
                ...values,
                cabinet_carc_thickness: carcaseThickness,
                cabinet_ext_thickness: exteriorThickness,
            },
            formValues
        );
    }, [formValues]);

    const multiPartValues: ConditionalValues[] = [
        {
            key: 'multiPart',
            condition:
                Boolean(formValues.microwaveOpeningHeight) ||
                Boolean(values?.upper_shelves && values?.lower_shelves),
            callback: generateMultiPart,
        },
    ];

    return {
        multiPartValues,
    };
};

export default useMultiPartDynamicValues;
