import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {PreviewFormValues} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';

const calculateBaseWithAngle = (
    hypotenuse: number,
    angleInDegrees: number
): number => {
    const angleInRadians = angleInDegrees * (Math.PI / 180);
    const base = hypotenuse * Math.cos(angleInRadians);
    return base;
};

export const toggleApplianceExtendedDoorHandler = (
    mesh: THREE.Group<THREE.Object3DEventMap>,
    variables: TemplateVariable,
    values: PreviewFormValues,
    carcaseThickness: number,
    isRightExtendedDoor = false
): AnimationHandler => {
    const openingAngle = 87;
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    const width = size.x;

    if (isRightExtendedDoor) {
        mesh.position.x = -width;
        mesh.scale.x = -1;
    } else {
        mesh.position.x = width;
        mesh.scale.x = -1;
    }

    const base = calculateBaseWithAngle(width, openingAngle);

    const radians = (openingAngle * Math.PI) / 180;
    const initialRotation = {y: 0};
    const openRotation = {y: isRightExtendedDoor ? -radians : radians};
    const closeRotation = {y: 0};

    const openDoor = (animationSpeed = 600) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        });

    const closeTween = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialRotation)
                .to(closeRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            tween.start();
        });

    const cabinetWidth = Number(values.cabinet_width) + carcaseThickness;
    const doorGap = Number(values.cabinet_door_gap);
    const threeDoorWidth = (cabinetWidth - doorGap * 2) / 3;

    const position = {
        x: variables?.isApplianceLeftBifoldRight
            ? threeDoorWidth * 2 + doorGap + 2
            : isRightExtendedDoor
            ? variables?.isApplianceLeftRightBifold
                ? threeDoorWidth + doorGap
                : -1
            : cabinetWidth - Number(values.cabinet_door_right) + 1,
    };

    let initialPosition = {...position};
    const openPosition = {
        x: isRightExtendedDoor
            ? cabinetWidth - base * 2 - Number(values.cabinet_door_right)
            : base * 2 + Number(values.cabinet_door_left),
    };
    let closePosition = {...position};

    if (variables?.isApplianceBifoldPair) {
        const fourDoorWidth = (cabinetWidth - doorGap * 3) / 4;

        if (isRightExtendedDoor) {
            const positionRight = {x: fourDoorWidth * 2 + doorGap * 2 - 2};
            initialPosition = {...positionRight};
            closePosition = {...positionRight};
        } else {
            const positionRight = {x: fourDoorWidth * 2 + doorGap};
            initialPosition = {...positionRight};
            closePosition = {...positionRight};
        }
    }

    const openDoorX = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .delay(0)
                .onUpdate(() => {
                    mesh.position.x = initialPosition.x;
                });

            openTween.start();
        });

    const closeTween2 = (animationSpeed = 600) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialPosition)
                .to(closePosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.position.x = initialPosition.x;
                });

            tween.start();
        });

    return {
        open: [openDoor, openDoorX],
        close: [closeTween, closeTween2],
    };
};
