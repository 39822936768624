import {useRef, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import * as THREE from 'three';
import {
    setImageDataUrl,
    getImageDataUrl,
    setHasPreview3DLoading,
} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {useDebouncedCallback} from 'use-debounce';

const useImageDataUrlTrigger = (
    renderer: React.MutableRefObject<THREE.WebGLRenderer>
) => {
    const dispatch = useAppDispatch();
    const imageDataUrl = useAppSelector(getImageDataUrl);
    const retryCount = useRef(0);

    const isWhiteImage = (dataUrl: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (!ctx) {
                    reject(new Error('Failed to get canvas context'));
                    return;
                }

                canvas.width = img.width;
                canvas.height = img.height;

                // Draw the image on the canvas
                ctx.drawImage(img, 0, 0);

                // Get image data
                const imageData = ctx.getImageData(
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
                const {data} = imageData;

                // Check if all pixels are white
                for (let i = 0; i < data.length; i += 4) {
                    const [r, g, b, a] = [
                        data[Number(i)],
                        data[i + 1],
                        data[i + 2],
                        data[i + 3],
                    ];
                    if (r !== 255 || g !== 255 || b !== 255 || a !== 255) {
                        resolve(false); // Not a white image
                        return;
                    }
                }

                resolve(true); // All pixels are white
            };

            img.onerror = () => {
                reject(new Error('Failed to load image'));
            };

            img.src = dataUrl;
        });
    };

    const setImageDataUrlTrigger = useDebouncedCallback(() => {
        const screenshotDataUrl =
            renderer.current?.domElement.toDataURL('image/jpeg');

        void isWhiteImage(screenshotDataUrl)
            .then((isWhite) => {
                const shouldRetry =
                    isWhite || screenshotDataUrl === imageDataUrl;

                if (shouldRetry && retryCount.current < 5) {
                    retryCount.current += 1;
                    setTimeout(setImageDataUrlTrigger, 500);
                } else {
                    retryCount.current = 0;
                    setTimeout(
                        () => dispatch(setHasPreview3DLoading(false)),
                        1000
                    );
                    dispatch(setImageDataUrl(screenshotDataUrl));
                }
            })
            .catch(() => dispatch(setHasPreview3DLoading(false)));
    }, 500);

    useEffect(() => {
        return () => setImageDataUrlTrigger?.cancel();
    }, []);

    return {setImageDataUrlTrigger, imageDataUrl};
};

export default useImageDataUrlTrigger;
