import React, {useCallback} from 'react';
import styled from 'styled-components';
import {PreviewPosition, Preview3DComponentProps} from 'Preview3D/types';
import PreviewControl from 'components/customer/Preview3D/components/PreviewControl';
import Preview3DNote from 'components/customer/Preview3D/components/Preview3DNote';
import useThree from 'components/customer/Preview3D/lib/useThree';
import {Loader} from 'shared/helpers';
import useUI from 'shared/useUI';
import {SidebarStatus} from 'store/customer/UISlice';
import Preview3DErrorBoundary from 'components/customer/Preview3D/components/Preview3DErrorBoundary';
import {SurveyButton} from 'shared/components/Surveys/SurveyButton';
import {SURVEYS} from 'shared/components/Surveys/helpers/useSurveys';
import {ProductDataStore} from 'components/customer/Preview3D/usePreview3DData';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';
import {Beta} from 'shared/components/Beta';

export const Preview3dComponentWithProvider = ({
    previewLocation,
    expanded,
    fullScreen,
    hiddenForScreenshot,
    raw,
}: Preview3DComponentProps<ProductDataStore>) => {
    const {values, isMiniBrowser} = use3DContext();
    const {
        setupCameraPosition,
        reloadScene,
        buttonLabel,
        previewPosition,
        setPreviewPosition,
        showTexture,
        isDoorOpenRef,
        variables,
        isLoading,
        handlePreviewRef,
        toggleTexture,
        handleClick,
    } = useThree(
        previewLocation,
        expanded,
        hiddenForScreenshot,
        fullScreen,
        raw
    );

    const {rightSidebar, isSmallScreenLayout} = useUI();

    const previewPositionChangedCallback = useCallback(
        (position: PreviewPosition) => {
            setupCameraPosition(position);
        },
        []
    );

    const onTogglePreviewHandler = useCallback(() => {
        toggleTexture();
        reloadScene();
    }, [showTexture]);

    const hasDoor = typeof values.cabinet_door_gap !== 'undefined';
    const includeDrawerFaces =
        typeof values.include_drawer_faces !== 'undefined'
            ? Boolean(values.include_drawer_faces)
            : true;

    const preview3D = (
        <Preview3D
            id="preview_3d"
            $reducedViewPort={
                rightSidebar === SidebarStatus.OPEN && isSmallScreenLayout
            }
            // eslint-disable-next-line react/jsx-no-bind
            ref={(ref) => handlePreviewRef(ref)}
            $hidden={hiddenForScreenshot}
        />
    );

    return (
        <Preview3DErrorBoundary>
            <Preview3DContainer
                $isMiniBrowser={isMiniBrowser}
                $expanded={expanded}
                $fullScreen={fullScreen}
                $hidden={hiddenForScreenshot}
                $noBorder={raw}>
                {raw ? (
                    preview3D
                ) : (
                    <>
                        <Loader relative loader={isLoading} />
                        <BetaStyled className="beta" />
                        <SurveyButton surveyKey={SURVEYS.ProductVisualizer3d} />
                        {preview3D}
                        <PreviewControl
                            handleClick={handleClick}
                            previewPosition={previewPosition}
                            setPreviewPosition={setPreviewPosition}
                            onPreviewPositionChanged={
                                previewPositionChangedCallback
                            }
                            toggleLabel={buttonLabel}
                            showToggle={
                                (Boolean(values.drawers) ||
                                    Boolean(values.shelves) ||
                                    Boolean(values.upper_shelves) ||
                                    Boolean(values.cabinet_door)) &&
                                !variables.noInteractions &&
                                !(
                                    !Boolean(variables.innerDrawerCount) &&
                                    !Boolean(values.drawers) &&
                                    hasDoor &&
                                    !includeDrawerFaces
                                )
                            }
                            onTogglePreview={onTogglePreviewHandler}
                            showTexture={showTexture}
                            isDoorOpen={isDoorOpenRef.current}
                            previewLocation={previewLocation}
                            expanded={expanded}
                            hideExpandIcon={fullScreen}
                        />
                    </>
                )}
            </Preview3DContainer>
            {fullScreen || hiddenForScreenshot ? null : <Preview3DNote />}
        </Preview3DErrorBoundary>
    );
};

const Preview3DContainer = styled.div<{
    $expanded: boolean;
    $fullScreen: boolean;
    $hidden: boolean;
    $noBorder: boolean;
    $isMiniBrowser: boolean;
}>`
    position: relative;
    height: ${({$isMiniBrowser}) => ($isMiniBrowser ? '265px' : '375px')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid rgb(var(--primary_colour));
    ${({$noBorder}) => ($noBorder ? `border-color: transparent;` : '')}
    overflow: hidden;
    ${({$expanded}) => ($expanded ? `height: 100%;` : '')}
    ${({$fullScreen}) =>
        $fullScreen
            ? `
        position: fixed; 
        top: 0;
        left: 0;
        width: 100vw; 
        height: 100vh;
        z-index: 9999;  
        `
            : ''}
    ${({$hidden}) => ($hidden ? `display: none;` : '')}
`;

const Preview3D = styled.div<{$reducedViewPort?: boolean; $hidden: boolean}>`
    height: 100%;
    flex-grow: 1;
    background: #fff;
    border-radius: 8px 0 0 8px;
    ${({$reducedViewPort}) => ($reducedViewPort ? `width: 320px;` : '')}
    ${({$hidden}) =>
        $hidden
            ? `height: 400px;
                width: 400px;`
            : ''}
`;

const BetaStyled = styled(Beta)`
    transform: rotate(-45deg);
    left: -45px;
    top: 15px;
    width: 150px;
    font-weight: 500;
    font-size: 15px;
`;
