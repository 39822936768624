import {useCallback} from 'react';
import {ShelfType, ConditionalValues} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {
    getShelvesFormValues,
    generateHorizontalShelves as getHorizontalShelves,
    generateComplexHorizontalShelves as getComplexHorizontalShelves,
    generateComplexVerticalShelves as getComplexVerticalShelves,
    generateUpperAndLowerShelves as getUpperAndLowerShelves,
    generateUpperAndLowerLeftRightShelves as getUpperAndLowerLeftRightShelves,
} from 'components/customer/Preview3DCommon/values/shelves';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

const useShelvesDynamicValues = (shelfType: ShelfType) => {
    const {values, productDataStore} = use3DContext();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );
    const {carcaseThickness, exteriorThickness} = useMaterialTexture();
    const valuesWithThickness = {
        ...values,
        cabinet_carc_thickness: carcaseThickness,
        cabinet_ext_thickness: exteriorThickness,
    };
    const formValues = getShelvesFormValues(values, shelfType);

    const generateHorizontalShelves = useCallback(() => {
        return getHorizontalShelves(valuesWithThickness, formValues, variables);
    }, [formValues]);

    const generateComplexHorizontalShelves = useCallback(() => {
        return getComplexHorizontalShelves(valuesWithThickness, formValues);
    }, [formValues]);

    const generateComplexVerticalShelves = useCallback(() => {
        return getComplexVerticalShelves(valuesWithThickness, formValues);
    }, [formValues]);

    const generateUpperAndLowerShelves = useCallback(() => {
        return getUpperAndLowerShelves(valuesWithThickness, formValues);
    }, [formValues]);

    const generateUpperAndLowerLeftRightShelves = useCallback(() => {
        return getUpperAndLowerLeftRightShelves(
            valuesWithThickness,
            formValues,
            variables
        );
    }, [formValues]);

    const generateKickerLadderFrames = useCallback(() => {
        const ladderFramesCount = formValues?.ladderFrames;
        const hasBackFace = Boolean(values.cabinet_edge_L1);
        const hasLeftFace = Boolean(values.cabinet_edge_W1);
        const hasRightFace = Boolean(values.cabinet_edge_W2);

        const inserts: {
            insert: number;
            depth?: number;
        }[] = [];

        const width = formValues?.width;
        const depth = values.cabinet_depth - carcaseThickness;

        for (let index = 1; index <= ladderFramesCount; index++) {
            inserts.push({
                insert: (width / (ladderFramesCount + 1)) * index,
                depth,
            });
        }

        return {
            inserts,
            exteriorDepth:
                Number(values.cabinet_depth) +
                carcaseThickness +
                (hasBackFace ? exteriorThickness : 0),
            exteriorFrontWidth:
                width +
                carcaseThickness +
                exteriorThickness * 2 -
                (!hasLeftFace ? exteriorThickness : 0) -
                (!hasRightFace ? exteriorThickness : 0),
            exteriorFrontX:
                width +
                carcaseThickness +
                (!hasRightFace ? 0 : exteriorThickness),
            exteriorBackWidth: width + carcaseThickness,
        };
    }, [formValues]);

    const generateBulkheadLadderFrames = useCallback(() => {
        const ladderFramesCount = formValues?.ladderFrames;
        const hasFrontFace = Boolean(values.cabinet_edge_L2);
        const hasBackFace = Boolean(values.cabinet_edge_L1);
        const hasLeftFace = Boolean(values.cabinet_edge_W1);
        const hasRightFace = Boolean(values.cabinet_edge_W2);
        const hasFrontAndBackFaces = hasFrontFace && hasBackFace;

        const inserts: {
            insert: number;
            depth?: number;
            positionY?: number;
        }[] = [];

        const width = formValues?.width;
        const depth = values.cabinet_depth - carcaseThickness;

        for (let index = 1; index <= ladderFramesCount; index++) {
            const frameDepth = hasFrontAndBackFaces ? depth : 80;

            inserts.push({
                insert: (width / (ladderFramesCount + 1)) * index,
                depth: frameDepth,
                positionY: hasFrontAndBackFaces
                    ? depth
                    : hasFrontFace
                    ? frameDepth
                    : depth,
            });
        }

        const bottomDepthAddition = hasFrontAndBackFaces ? 0 : carcaseThickness;

        return {
            inserts,
            hasFrontFace,
            hasBackFace,
            hasLeftFace,
            hasRightFace,
            leftSideDepth: hasLeftFace ? depth + carcaseThickness : 80,
            rightSideDepth: hasRightFace ? depth + carcaseThickness : 80,
            exteriorDepth:
                Number(values.cabinet_depth) +
                carcaseThickness +
                (hasBackFace ? exteriorThickness : 0),
            exteriorFrontWidth:
                width +
                carcaseThickness +
                exteriorThickness * 2 -
                (!hasLeftFace ? exteriorThickness : 0) -
                (!hasRightFace ? exteriorThickness : 0),
            exteriorFrontX:
                width +
                carcaseThickness +
                (!hasRightFace ? 0 : exteriorThickness),
            exteriorBackWidth: width + carcaseThickness,
            bottomPanelY:
                depth - (hasFrontAndBackFaces ? 80 : 0) + bottomDepthAddition,
            bottomPanelDepth:
                depth -
                80 -
                (hasFrontAndBackFaces ? 80 : 0) +
                bottomDepthAddition,
        };
    }, [formValues]);

    const shelfValues: ConditionalValues[] = [
        {
            key: 'simpleShelves',
            condition: typeof values.shelves !== 'undefined',
            callback: generateHorizontalShelves,
        },
        {
            key: 'complexHorizontalShelves',
            condition: Boolean(formValues?.shelvesHorizontalCount),
            callback: generateComplexHorizontalShelves,
        },
        {
            key: 'complexVerticalShelves',
            condition: Boolean(formValues?.shelvesVerticalCount),
            callback: generateComplexVerticalShelves,
        },
        {
            key: 'pantryShelves',
            condition:
                Boolean(values?.upper_shelves) &&
                variables?.shelfType === 'PANTRY',
            callback: generateUpperAndLowerShelves,
        },
        {
            key: 'broomShelves',
            condition:
                Boolean(values?.upper_shelves) && Boolean(values?.shelves),
            callback: generateUpperAndLowerLeftRightShelves,
        },
        {
            key: 'kicker',
            condition: Boolean(formValues?.ladderFrames),
            callback: generateKickerLadderFrames,
        },
        {
            key: 'bulkhead',
            condition: Boolean(formValues?.ladderFrames),
            callback: generateBulkheadLadderFrames,
        },
    ];

    return {
        shelfValues,
    };
};

export default useShelvesDynamicValues;
