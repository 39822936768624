import React, {useMemo} from 'react';
import * as THREE from 'three';
import {
    AdvancedShelf,
    AdvancedLowerShelf,
    AdvancedUpperShelf,
    PreviewLocation,
    AdvancedMiddleShelf,
} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {MaterialType} from 'components/customer/Materials/entity';
import {
    convertShelfPositionToPercentage,
    reduceMaterialOpacity,
    getShelfStyle,
} from 'components/customer/Preview3D/helpers';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';
import {
    DEFAULT_VENT_FRONT_WIDTH,
    DEFAULT_VENT_WIDTH,
} from 'components/customer/Preview3D/values/useRangehoodDynamicValues';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

const useShapedPanel = (
    scene: React.MutableRefObject<THREE.Scene>,
    showTexture?: boolean,
    previewLocation?: PreviewLocation
) => {
    const {values, productDataStore} = use3DContext();
    const isLsSolidTop = values.cabinet_top == 36;
    const isFfSolidTop = values.cabinet_top == 96;
    const isFfRailOnFlat = values.cabinet_top == 95;
    const horizontalGrainCarc = Boolean(values.hor_grain_carc);
    const horizontalGrainExt = Boolean(values.hor_grain_ext);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );
    const isLeftReturn = variables?.isLeftReturn;

    const {
        carcaseTexture,
        carcaseEdgeTexture,
        carcaseThickness,
        carcaseSubstrateMaterial,
        exteriorTexture,
        exteriorEdgeTexture,
        exteriorThickness,
    } = useMaterialTexture();
    const selectedCarcaseMaterial = useAppSelector(
        (state) => getMaterial(state, MaterialType.CARCASE),
        shallowEqual
    );
    const nonSupplyCarcase = selectedCarcaseMaterial?.brand_id === 23;
    const defaultWidthOffset = useMemo(
        () => carcaseThickness * 3,
        [carcaseThickness]
    );

    const generateRectMesh = ({
        width,
        height,
        carcaseTexture,
        carcaseEdgeTexture,
    }: {
        width: number;
        height: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
    }) => {
        const shape = new THREE.Shape();

        // Start at the bottom-left corner
        shape.moveTo(0, 0);

        // Draw the bottom line
        shape.lineTo(width, 0);

        // Draw the right line
        shape.lineTo(width, height);

        // Draw the top line
        shape.lineTo(0, height);

        // Draw the left line to close the shape
        shape.lineTo(0, 0);

        // Extrude settings for the 3D geometry
        const extrudeSettings = {
            steps: 1,
            depth: carcaseThickness,
            bevelEnabled: false,
        };

        // Create the extruded geometry
        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        // Apply the carcase material with edge texture
        const materials = applyCarcaseMaterial(
            geometry,
            carcaseTexture,
            carcaseEdgeTexture
        );

        // Apply materials to faces
        for (let i = 0; i < geometry.groups.length; i++) {
            geometry.groups[Number(i)].materialIndex = i % 2; // Alternate materials
        }

        // Create and return the mesh
        return new THREE.Mesh(geometry, materials);
    };

    const generateLShapeMesh = ({
        leftWidth,
        leftDepth,
        rightDepth,
        radius,
        rightWidth,
        carcaseTexture,
        carcaseEdgeTexture,
    }: {
        leftWidth: number;
        leftDepth: number;
        rightDepth: number;
        rightWidth: number;
        radius: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
    }) => {
        // Create the shape
        const shape = new THREE.Shape();

        // Start at the bottom-left corner
        shape.moveTo(0, 0);

        // Draw the left vertical line
        shape.lineTo(0, leftWidth);

        // Draw the top horizontal line to the right (end of left width)
        shape.lineTo(leftDepth, leftWidth);

        // Draw the vertical line downwards to the radius start point
        shape.lineTo(leftDepth, rightDepth + radius);

        // Create the rounded corner with absarc
        shape.absarc(
            leftDepth + radius, // x coordinate of arc's center
            rightDepth + radius, // y coordinate of arc's center
            radius, // radius of the arc
            Math.PI, // start angle (180 degrees in radians)
            Math.PI * 1.5, // end angle (270 degrees in radians)
            false // clockwise direction
        );

        // Draw the remaining horizontal line to the right
        shape.lineTo(rightWidth + radius, rightDepth);

        // Draw the right vertical line upwards
        shape.lineTo(rightWidth + radius, 0);

        // Close the shape by drawing the bottom horizontal line to the left
        shape.lineTo(0, 0);

        const extrudeSettings = {
            steps: 1,
            depth: carcaseThickness,
            bevelEnabled: false,
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        const materials = applyCarcaseMaterial(
            geometry,
            carcaseTexture,
            carcaseEdgeTexture
        );

        // Apply materials to faces
        for (let i = 0; i < geometry.groups.length; i++) {
            geometry.groups[Number(i)].materialIndex = i % 2; // Alternate materials
        }

        // Create the mesh
        return new THREE.Mesh(geometry, materials);
    };

    const createLineSegments = (mesh: THREE.Mesh) => {
        const edges = new THREE.EdgesGeometry(mesh.geometry);

        const lineMaterial = new THREE.LineBasicMaterial({
            color: '#333',
        });

        if (nonSupplyCarcase) reduceMaterialOpacity(lineMaterial);

        return new THREE.LineSegments(edges, lineMaterial);
    };

    const applyCarcaseMaterial = (
        geometry: THREE.ExtrudeGeometry,
        texture: THREE.Texture,
        edgeTexture: THREE.Texture,
        useSubstrateEdge?: boolean,
        flipGrain?: boolean,
        hasHorizontalGrain = horizontalGrainCarc
    ) => {
        if (texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0.5);
            texture.repeat.set(0.003, 0.003);

            const showHorizontalGrain = flipGrain
                ? !hasHorizontalGrain
                : hasHorizontalGrain;

            if (showHorizontalGrain) {
                texture.rotation = (Math.PI / 4) * 2;
                texture.center = new THREE.Vector2(0.5, 0.5);
                texture.needsUpdate = true;
            } else {
                texture.rotation = 0;
                texture.center = new THREE.Vector2(0.5, 0.5);
                texture.needsUpdate = true;
            }
        }

        if (edgeTexture) {
            edgeTexture.wrapS = edgeTexture.wrapT = THREE.RepeatWrapping;
            edgeTexture.offset.set(0, 0.5);
            edgeTexture.repeat.set(0.003, 0.003);
        }

        // Create a material
        const material = new THREE.MeshStandardMaterial({
            map: texture,
        });
        const edgeMaterial = new THREE.MeshPhongMaterial({
            map: edgeTexture?.source?.data ? edgeTexture : texture,
        });

        if (!showTexture) {
            material.transparent = true;
            material.opacity = 0;

            if (useSubstrateEdge && carcaseSubstrateMaterial) {
                carcaseSubstrateMaterial.transparent = true;
                carcaseSubstrateMaterial.opacity = 0;
            } else {
                edgeMaterial.transparent = true;
                edgeMaterial.opacity = 0;
            }
        } else {
            if (carcaseSubstrateMaterial) {
                carcaseSubstrateMaterial.transparent = false;
                carcaseSubstrateMaterial.opacity = 1;
            }
        }

        if (nonSupplyCarcase) {
            reduceMaterialOpacity(material);
            reduceMaterialOpacity(edgeMaterial);
        }

        const materials = [
            material,
            useSubstrateEdge ? carcaseSubstrateMaterial : edgeMaterial,
            edgeMaterial,
        ];

        for (let i = 0; i < geometry.groups.length; i++) {
            geometry.groups[Number(i)].materialIndex = i % 2;
        }

        return materials;
    };

    const generateUShapeMesh = ({
        width,
        depth,
        cutoutWidth,
        cutoutHeight,
        radius,
        carcaseTexture,
        carcaseEdgeTexture,
        complexRadii,
        sides,
        customCutoutHeight,
    }: {
        width: number;
        depth: number;
        cutoutWidth: number;
        cutoutHeight: number;
        radius: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
        complexRadii?: {
            interiorRadius: number;
            exteriorRadius: number;
        };
        sides?: {
            left: number;
            right: number;
        };
        customCutoutHeight?: number;
    }) => {
        const shape = new THREE.Shape();
        const exteriorRadius = complexRadii
            ? complexRadii.exteriorRadius
            : radius;
        const interiorRadius = complexRadii
            ? complexRadii.interiorRadius
            : radius;

        const leftLength = sides ? sides.left : (width - cutoutWidth) / 2;
        const rightLength = sides
            ? width - sides.right
            : (width + cutoutWidth) / 2;

        const computedCutoutHeight = customCutoutHeight
            ? customCutoutHeight
            : cutoutHeight;

        // Outer shape with rounded corners and open bottom, starting from (0,0)
        shape.moveTo(0, 0);
        shape.absarc(width, 0, 0, Math.PI * 1.5, 0, false);
        shape.lineTo(width, depth);
        shape.lineTo(0, depth);
        shape.lineTo(0, 0);

        // Draw the cutout using lines and arcs
        shape.lineTo(leftLength - exteriorRadius, 0);
        shape.absarc(
            leftLength - exteriorRadius,
            exteriorRadius,
            exteriorRadius,
            Math.PI * 1.5,
            0,
            false
        );
        shape.lineTo(leftLength, computedCutoutHeight - interiorRadius);
        shape.absarc(
            leftLength + interiorRadius,
            computedCutoutHeight - interiorRadius,
            interiorRadius,
            Math.PI,
            Math.PI * 0.5,
            true
        );
        shape.lineTo(rightLength - interiorRadius, computedCutoutHeight);
        shape.absarc(
            rightLength - interiorRadius,
            computedCutoutHeight - interiorRadius,
            interiorRadius,
            Math.PI * 0.5,
            0,
            true
        );
        shape.lineTo(rightLength, computedCutoutHeight - interiorRadius);
        shape.lineTo(rightLength, exteriorRadius);
        shape.absarc(
            rightLength + exteriorRadius,
            exteriorRadius,
            exteriorRadius,
            Math.PI,
            Math.PI * 1.5,
            false
        );

        const extrudeSettings = {
            steps: 1,
            depth: carcaseThickness,
            bevelEnabled: false,
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        const materials = applyCarcaseMaterial(
            geometry,
            carcaseTexture,
            carcaseEdgeTexture,
            false,
            true
        );

        return new THREE.Mesh(geometry, materials);
    };

    const getLShapeOffsets = () => {
        let widthOffset = 0;

        switch (Number(carcaseThickness)) {
            case 16.5:
                widthOffset = defaultWidthOffset;
                break;
            case 25.5:
                widthOffset = carcaseThickness * 2.3;
                break;
            case 12.5:
            case 12:
                widthOffset = carcaseThickness * 3.7 - 1;
                break;
            default:
                widthOffset = carcaseThickness * 2;
                break;
        }

        return [widthOffset];
    };

    const getCornerShapeOffsets = () => {
        let widthOffset = 0;

        switch (Number(carcaseThickness)) {
            case 12.5:
            case 12:
            case 25.5:
                widthOffset = carcaseThickness * 3 + 1;
                break;
            default:
                widthOffset = defaultWidthOffset;
                break;
        }

        return [widthOffset];
    };

    const getCornerLShapeOffsets = () => {
        let widthOffset = 0;

        switch (Number(carcaseThickness)) {
            case 16.5:
                widthOffset = defaultWidthOffset;
                break;
            case 25.5:
                widthOffset = carcaseThickness * 2;
                break;
            case 12.5:
            case 12:
                widthOffset = carcaseThickness * 4;
                break;
            default:
                widthOffset = carcaseThickness * 1.5;
                break;
        }

        return [widthOffset];
    };

    const addLabelMesh = (
        shelfLabel: string,
        thickness: number,
        parentMesh: THREE.Mesh,
        positionY: number,
        positionXOffset = 0
    ) => {
        if (previewLocation !== 'SHELVES') return;

        const fontLoader = new FontLoader();

        fontLoader.load(
            '/templates/3D/fonts/Poppins SemiBold_Regular.json',
            (font) => {
                const textGeometry = new TextGeometry(shelfLabel, {
                    font,
                    size: 4,
                    height: 1,
                    depth: 0.5,
                });

                const textMesh = new THREE.Mesh(
                    textGeometry,
                    new THREE.MeshStandardMaterial({
                        color: '#333',
                    })
                );

                const scale = 7;
                textMesh.scale.set(scale, scale, scale);

                const boundingBox = new THREE.Box3().setFromObject(textMesh);
                const meshWidth = boundingBox.max.x - boundingBox.min.x;

                textMesh.rotation.x = (90 * Math.PI) / 180;

                const box = new THREE.Box3().setFromObject(parentMesh);
                const meshXParent = box.max.x - box.min.x;

                const isRightReturnProduct =
                    !variables?.isLeftReturn && variables.isReturnProduct;

                let positionX =
                    meshXParent / 2 - meshWidth / 2 - positionXOffset;

                if (isRightReturnProduct) {
                    textMesh.scale.x = -textMesh.scale.x;
                }

                if (isRightReturnProduct) {
                    positionX = meshXParent - 10;
                }

                if (isLeftReturn) {
                    positionX = meshXParent - meshWidth - 10;
                }

                textMesh.position.set(positionX, positionY, thickness + 5);

                parentMesh.add(textMesh);
            }
        );
    };

    const renderLShapedPanel = () => {
        const height = Number(values.cabinet_height);
        const radius = 20;

        const rightWidth =
            values.cabinet_right_width - radius - carcaseThickness;
        const leftWidth = Number(values.cabinet_left_width) + 2;

        const shelvesCount = Number(values.simple_shelves_amount);
        const advancedShelves = values.shelves as unknown as AdvancedShelf[];

        const meshes: THREE.Mesh[] = [];
        const isUpper3DoorProduct =
            variables?.is3DoorProduct && variables?.isUpperProduct;

        for (
            let index = 0;
            index <
            shelvesCount +
                1 +
                (isLsSolidTop || variables?.isOpenLShape || isUpper3DoorProduct
                    ? 1
                    : 0);
            index++
        ) {
            const leftDepthValue =
                Number(values.cabinet_left_depth) - carcaseThickness - 1;
            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth = leftDepthValue;
            let shelvePosition = (height / (shelvesCount + 1)) * index;
            let style = '';

            const isLsSolidTopSelected = index === shelvesCount + 1;
            const shouldApplyOffset = index !== 0 && index !== shelvesCount + 1;

            if (!values.cabinet_simple_shelves) {
                const selectedShelf = advancedShelves[index - 1];

                if (selectedShelf) {
                    shelvePosition =
                        height *
                        convertShelfPositionToPercentage(
                            selectedShelf.shelf_position,
                            height
                        );

                    rightDepth =
                        values.cabinet_right_depth -
                        (shouldApplyOffset ? selectedShelf.shelf_offset : 0);
                    leftDepth =
                        leftDepthValue -
                        (shouldApplyOffset ? selectedShelf.shelf_offset : 0);

                    style = getShelfStyle(selectedShelf?.shelf_style);
                }
            }

            const mesh = generateLShapeMesh({
                leftWidth,
                leftDepth,
                rightDepth,
                radius,
                rightWidth,
                carcaseTexture,
                carcaseEdgeTexture,
            });

            mesh.position.set(
                carcaseThickness,
                leftWidth - 2,
                shelvePosition -
                    (isLsSolidTopSelected ? carcaseThickness : 0) +
                    1
            );
            mesh.scale.y = -1;

            if (index !== 0 && index !== shelvesCount + 1) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const renderUShapedPanel = () => {
        const radius = 50;
        const advancedShelves = values.shelves as unknown as AdvancedShelf[];
        const shelvesCount = advancedShelves?.length;

        const meshes: THREE.Mesh[] = [];

        for (let index = 1; index <= shelvesCount; index++) {
            if (!values.cabinet_simple_shelves) {
                const selectedShelf = advancedShelves[index - 1];

                if (
                    selectedShelf &&
                    selectedShelf.shelf_type === 9 &&
                    !variables.isReturnProduct
                ) {
                    const width =
                        Number(values.cabinet_width) - carcaseThickness;
                    const height = Number(values.cabinet_height);
                    const depth =
                        Number(values.cabinet_depth) -
                        Number(selectedShelf.shelf_offset) -
                        carcaseThickness;
                    const cutoutWidth = width * 0.6;
                    const cutoutHeight = depth * 0.5;

                    const mesh = generateUShapeMesh({
                        width,
                        depth,
                        cutoutWidth,
                        cutoutHeight,
                        radius,
                        carcaseTexture,
                        carcaseEdgeTexture,
                    });

                    const lineSegments = createLineSegments(mesh);

                    mesh.position.set(
                        carcaseThickness,
                        Number(selectedShelf.shelf_offset),
                        height *
                            convertShelfPositionToPercentage(
                                selectedShelf.shelf_position,
                                height
                            )
                    );

                    const style = getShelfStyle(selectedShelf?.shelf_style);
                    const shelfLabel = `Shelf ${index} - ${style}`;

                    addLabelMesh(
                        shelfLabel,
                        carcaseThickness,
                        mesh,
                        cutoutHeight + 20
                    );

                    mesh.add(lineSegments);
                    meshes.push(mesh);
                }
            }
        }

        return meshes;
    };

    const getLShapeOffsetsForReturn = () => {
        let widthOffset = 0;
        let positionXOffset = 0;
        let positionXOffsetRightReturn = 0;

        switch (Number(carcaseThickness)) {
            case 16.5:
                widthOffset = carcaseThickness * 4;
                positionXOffset = carcaseThickness + 5;
                positionXOffsetRightReturn =
                    carcaseThickness + carcaseThickness / 2;
                break;
            case 25.5:
                widthOffset = carcaseThickness * 3;
                positionXOffset = carcaseThickness;
                positionXOffsetRightReturn =
                    carcaseThickness + carcaseThickness / 2;
                break;
            case 12.5:
            case 12:
                widthOffset = carcaseThickness * 5;
                positionXOffset = carcaseThickness * 2;
                positionXOffsetRightReturn =
                    carcaseThickness + carcaseThickness / 2;
                break;
            default:
                widthOffset = carcaseThickness * 2.5;
                positionXOffset = carcaseThickness * 0.5;
                positionXOffsetRightReturn = carcaseThickness;
                break;
        }

        return [widthOffset, positionXOffset, positionXOffsetRightReturn];
    };

    const renderLShapedPanelForReturn = () => {
        const advancedShelves = values.shelves as unknown as AdvancedShelf[];
        const shelvesCount = advancedShelves?.length;

        const includeFaces =
            typeof values.include_drawer_faces !== 'undefined'
                ? Boolean(values.include_drawer_faces)
                : true;
        const voidWidth =
            values.cabinet_void_width &&
            Boolean(values.cabinet_cover_void) &&
            includeFaces
                ? Number(values.cabinet_void_width)
                : 0;
        const returnPanelWidth = values.cabinet_return_panel_width
            ? parseFloat(values.cabinet_return_panel_width?.toString())
            : 0;

        const [widthOffset] = getLShapeOffsetsForReturn();

        const meshes: THREE.Mesh[] = [];

        for (let index = 1; index <= shelvesCount; index++) {
            if (!values.cabinet_simple_shelves) {
                const selectedShelf = advancedShelves[index - 1];

                if (selectedShelf && selectedShelf.shelf_type === 9) {
                    const height = Number(values.cabinet_height);
                    const leftWidth =
                        Number(values.cabinet_depth) -
                        carcaseThickness -
                        Number(selectedShelf.shelf_offset) +
                        carcaseThickness / 2;
                    const leftDepth =
                        returnPanelWidth -
                        voidWidth -
                        Number(selectedShelf.shelf_offset) -
                        carcaseThickness;
                    const rightDepth =
                        Number(values.cabinet_depth) * 0.7 -
                        Number(selectedShelf.shelf_offset);
                    const radius = 50;
                    const rightWidth =
                        Number(values.cabinet_width) - widthOffset;

                    const shelvePosition =
                        (height / (shelvesCount + 1)) * index;

                    const mesh = generateLShapeMesh({
                        leftWidth,
                        leftDepth,
                        rightDepth,
                        radius,
                        rightWidth,
                        carcaseTexture,
                        carcaseEdgeTexture,
                    });

                    let positionX = carcaseThickness;

                    if (!isLeftReturn) {
                        positionX = rightWidth + widthOffset + carcaseThickness;
                        mesh.scale.x = -1;
                    }

                    mesh.position.set(
                        positionX,
                        leftWidth +
                            Number(selectedShelf.shelf_offset) -
                            carcaseThickness / 2,
                        shelvePosition
                    );

                    mesh.scale.y = -1;

                    const style = getShelfStyle(selectedShelf?.shelf_style);
                    const shelfLabel = `Shelf ${index} - ${style}`;

                    addLabelMesh(
                        shelfLabel,
                        carcaseThickness,
                        mesh,
                        rightDepth - 20
                    );

                    const lineSegments = createLineSegments(mesh);

                    mesh.add(lineSegments);
                    meshes.push(mesh);
                }
            }
        }

        return meshes;
    };

    /**
     * Creates an L-shaped partition with specified dimensions and textures.
     * @param {Object} params - The parameters for the partition.
     * @param {number} params.leftWidth - The width of the left section.
     * @param {number} params.leftDepth - The depth of the left section.
     * @param {number} params.rightDepth - The depth of the right section.
     * @param {number} params.radius - The radius of the corner.
     * @param {number} params.rightWidth - The width of the right section.
     * @param {THREE.Texture} params.carcaseTexture - The texture for the main carcase.
     * @param {THREE.Texture} params.carcaseEdgeTexture - The texture for the edges.
     * @param {number} params.heightOffset - The height offset for the partition.
     * @param {string} params.style - The style of the partition.
     * @param {number} params.index - The index identifier for the partition.
     * @return {THREE.Mesh<THREE.ExtrudeGeometry, (THREE.MeshStandardMaterial | THREE.MeshPhongMaterial)[], THREE.Object3DEventMap>} - Indicates whether the partition was successfully created.
     */
    function createLShapePartition({
        leftWidth,
        leftDepth,
        rightDepth,
        radius,
        rightWidth,
        carcaseTexture,
        carcaseEdgeTexture,
        heightOffset,
        usePlain = false,
    }: {
        leftWidth: number;
        leftDepth: number;
        rightDepth: number;
        radius: number;
        rightWidth: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
        style: string;
        index: number;
        heightOffset: number;
        usePlain: boolean;
    }) {
        const [widthOffset] = getLShapeOffsetsForReturn();
        // create object to render
        const partitionMesh = usePlain
            ? generateRectMesh({
                  width: values.cabinet_width - carcaseThickness,
                  height: leftDepth,
                  carcaseTexture,
                  carcaseEdgeTexture,
              })
            : generateLShapeMesh({
                  leftWidth: leftWidth - 3.5,
                  leftDepth,
                  rightDepth,
                  radius,
                  rightWidth,
                  carcaseTexture,
                  carcaseEdgeTexture,
              });

        let positionX = carcaseThickness;

        // determines if the partition should face right or left
        if (!isLeftReturn) {
            positionX = rightWidth + widthOffset;
            partitionMesh.scale.x = -1;
        }

        partitionMesh.scale.y = -1;

        // assign position
        partitionMesh.position.set(positionX, leftWidth, heightOffset);

        return partitionMesh;
    }

    const renderLShapedPanelForPantryReturn = () => {
        const includeFaces =
            typeof values.include_drawer_faces !== 'undefined'
                ? Boolean(values.include_drawer_faces)
                : true;
        const voidWidth =
            values.cabinet_void_width &&
            Boolean(values.cabinet_cover_void) &&
            includeFaces
                ? Number(values.cabinet_void_width)
                : 0;
        const returnPanelWidth = values.cabinet_return_panel_width
            ? parseFloat(values.cabinet_return_panel_width?.toString())
            : 0;

        const upperShelvesCount = values?.upper_shelves
            ? (values?.upper_shelves as unknown as Array<number>)?.length
            : 0;
        const lowerShelvesCount = values?.lower_shelves
            ? (values?.lower_shelves as unknown as Array<number>)?.length
            : 0;

        const upperShelves =
            values.upper_shelves as unknown as AdvancedUpperShelf[];
        const lowerShelves =
            values.lower_shelves as unknown as AdvancedLowerShelf[];
        const shelvesHeightReference = {
            upper_shelves:
                parseFloat(values.cabinet_height?.toString()) -
                values.cabinet_partition_height,
            lower_shelves: values.cabinet_partition_height,
        };

        const upperShelfOffset = shelvesHeightReference.lower_shelves;
        const height = Number(values.cabinet_height) - upperShelfOffset;

        const isLeftReturn = variables?.isLeftReturn;
        const [widthOffset] = getLShapeOffsetsForReturn();

        const meshes: THREE.Mesh[] = [];

        for (let index = 1; index <= upperShelvesCount; index++) {
            const selectedShelf = upperShelves[index - 1];

            if (selectedShelf && Number(selectedShelf.upper_shelf_type) === 9) {
                const leftWidth =
                    Number(values.cabinet_depth) -
                    carcaseThickness -
                    Number(selectedShelf.upper_shelf_offset) +
                    carcaseThickness / 2;
                const leftDepth =
                    returnPanelWidth -
                    voidWidth -
                    Number(selectedShelf.upper_shelf_offset) -
                    carcaseThickness;
                const rightDepth =
                    Number(values.cabinet_depth) * 0.7 -
                    Number(selectedShelf.upper_shelf_offset);
                const radius = 50;
                const rightWidth = Number(values.cabinet_width) - widthOffset;

                const shelvePosition =
                    height *
                        convertShelfPositionToPercentage(
                            selectedShelf.upper_shelf_position,
                            height
                        ) +
                    upperShelfOffset;

                const mesh = generateLShapeMesh({
                    leftWidth: leftWidth - 3.5,
                    leftDepth,
                    rightDepth,
                    radius,
                    rightWidth,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });

                let positionX = carcaseThickness;

                if (!isLeftReturn) {
                    positionX = rightWidth + widthOffset;
                    mesh.scale.x = -1;
                }

                mesh.position.set(
                    positionX,
                    leftWidth +
                        Number(selectedShelf.upper_shelf_offset) -
                        carcaseThickness / 2,
                    shelvePosition
                );

                mesh.scale.y = -1;

                const style = getShelfStyle(selectedShelf?.upper_shelf_style);
                const shelfLabel = `Upper Shelf ${index} - ${style}`;

                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth - 20
                );

                const lineSegments = createLineSegments(mesh);
                mesh.add(lineSegments);
                meshes.push(mesh);
            }
        }

        const totalShelfCount = upperShelvesCount + lowerShelvesCount;
        const height2 = upperShelfOffset;
        const radius = 50;
        const rightWidth = Number(values.cabinet_width) - widthOffset;
        const partitionLabel = `Horizontal Partition`;

        // this section handles the partition
        // the rule of thumb is that partitions should be present all the time
        // and not depending on either top or bottom shelf

        const hasUpperShelf = upperShelves !== null && upperShelves.length > 0;
        const hasLowerShelf = lowerShelves !== null && lowerShelves.length > 0;

        const shelfOffset = hasUpperShelf
            ? upperShelves[0].upper_shelf_offset
            : hasLowerShelf
            ? lowerShelves[0].lower_shelf_offset
            : 0;

        const shelfStyle = hasUpperShelf
            ? upperShelves[0].upper_shelf_style
            : hasLowerShelf
            ? lowerShelves[0].lower_shelf_style
            : 0;

        const style = getShelfStyle(shelfStyle);

        const leftWidth =
            Number(values.cabinet_depth) -
            carcaseThickness -
            Number(shelfOffset) +
            carcaseThickness / 2;
        const leftDepth =
            returnPanelWidth -
            voidWidth -
            Number(shelfOffset) -
            carcaseThickness;
        const rightDepth =
            Number(values.cabinet_depth) * 0.7 - Number(shelfOffset);

        const usePlain =
            lowerShelves.length === 0 ||
            (hasLowerShelf && lowerShelves[0].lower_shelf_type === 5) ||
            totalShelfCount === 0;

        // define upper partition mesh when concluding out the upper shelves
        const upperPartitionMesh = createLShapePartition({
            leftWidth,
            leftDepth,
            rightDepth,
            radius,
            rightWidth,
            carcaseTexture,
            carcaseEdgeTexture,
            style,
            index: 0,
            heightOffset: upperShelfOffset,
            usePlain: usePlain,
        });

        // enforce label mesh to the upper partition
        addLabelMesh(
            partitionLabel,
            carcaseThickness,
            upperPartitionMesh,
            rightDepth
        );

        // added line segments
        const upperPartitionLineSegment =
            createLineSegments(upperPartitionMesh);
        upperPartitionMesh.add(upperPartitionLineSegment);

        // Bind upper partition
        meshes.push(upperPartitionMesh);

        // checks if the product has a was a 2 part and therefore has 2 partitions
        if (variables.is2Part) {
            // defined the second partition mesh
            const bottomPartitionMesh = createLShapePartition({
                leftWidth,
                leftDepth,
                rightDepth,
                radius,
                rightWidth,
                carcaseTexture,
                carcaseEdgeTexture,
                style,
                index: 0,
                heightOffset: upperShelfOffset - 18, // Position slightly below the upper partition
                usePlain: usePlain,
            });

            // define borderlines
            const bottomPartitionLineSegment =
                createLineSegments(bottomPartitionMesh);
            // add border lines to mesh
            bottomPartitionMesh.add(bottomPartitionLineSegment);

            // bind lower partition
            meshes.push(bottomPartitionMesh);
        }

        for (let index = 1; index <= lowerShelvesCount; index++) {
            const selectedShelf = lowerShelves[index - 1];

            if (selectedShelf && Number(selectedShelf.lower_shelf_type) === 9) {
                const leftWidth =
                    Number(values.cabinet_depth) -
                    carcaseThickness -
                    Number(selectedShelf.lower_shelf_offset) +
                    carcaseThickness / 2;
                const leftDepth =
                    returnPanelWidth -
                    voidWidth -
                    Number(selectedShelf.lower_shelf_offset) -
                    carcaseThickness;
                const rightDepth =
                    Number(values.cabinet_depth) * 0.7 -
                    Number(selectedShelf.lower_shelf_offset);
                const radius = 50;
                const rightWidth = Number(values.cabinet_width) - widthOffset;

                const shelvePosition =
                    height2 *
                    convertShelfPositionToPercentage(
                        selectedShelf.lower_shelf_position,
                        height2
                    );

                const mesh = generateLShapeMesh({
                    leftWidth: leftWidth - 3.5,
                    leftDepth,
                    rightDepth,
                    radius,
                    rightWidth,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });

                let positionX = carcaseThickness;

                if (!isLeftReturn) {
                    positionX = rightWidth + widthOffset;
                    mesh.scale.x = -1;
                }

                mesh.position.set(
                    positionX,
                    leftWidth +
                        Number(selectedShelf.lower_shelf_offset) -
                        carcaseThickness / 2,
                    shelvePosition
                );

                mesh.scale.y = -1;

                const style = getShelfStyle(selectedShelf?.lower_shelf_style);
                const shelfLabel = `Lower Shelf ${index} - ${style}`;

                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth - 20
                );

                const lineSegments = createLineSegments(mesh);

                mesh.add(lineSegments);
                meshes.push(mesh);
            }
        }

        return meshes;
    };

    const addUShapeShelf = ({
        width,
        radius,
        carcaseTexture,
        carcaseEdgeTexture,
        positionZ,
        shelfOffset,
        positionX,
        shelfLabel,
    }: {
        width: number;
        radius: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
        positionZ: number;
        positionX: number;
        shelfOffset: number;
        shelfLabel: string;
    }) => {
        const depth =
            Number(values.cabinet_depth) -
            Number(shelfOffset) -
            carcaseThickness;
        const cutoutWidth = width * 0.6;
        const cutoutHeight = depth * 0.5;
        const computedRadius = width < 250 ? 30 : radius;

        const mesh = generateUShapeMesh({
            width: width - carcaseThickness,
            depth,
            cutoutWidth,
            cutoutHeight,
            radius: computedRadius,
            carcaseTexture,
            carcaseEdgeTexture,
        });

        const lineSegments = createLineSegments(mesh);

        mesh.position.set(positionX, Number(shelfOffset), positionZ);

        addLabelMesh(shelfLabel, carcaseThickness, mesh, cutoutHeight + 20);

        mesh.add(lineSegments);

        return mesh;
    };

    const renderUpperAndLowerUShapeShelves = () => {
        const upperShelvesCount = values?.upper_shelves
            ? (values?.upper_shelves as unknown as Array<number>)?.length
            : 0;
        const lowerShelvesCount = values?.lower_shelves
            ? (values?.lower_shelves as unknown as Array<number>)?.length
            : 0;

        const upperShelves =
            values.upper_shelves as unknown as AdvancedUpperShelf[];
        const lowerShelves =
            values.lower_shelves as unknown as AdvancedLowerShelf[];
        const shelvesHeightReference = {
            upper_shelves:
                Number(values.cabinet_height) -
                Number(values.cabinet_partition_height),
            lower_shelves: Number(values.cabinet_partition_height),
        };

        const upperShelfOffset = shelvesHeightReference.lower_shelves;
        const height = Number(values.cabinet_height) - upperShelfOffset;
        const radius = 50;

        const width = Number(values.cabinet_width);
        const positionX = carcaseThickness;

        const meshes: THREE.Mesh[] = [];

        for (let index = 1; index <= upperShelvesCount; index++) {
            const selectedShelf = upperShelves[index - 1];

            if (selectedShelf && selectedShelf.upper_shelf_type === 9) {
                const style = getShelfStyle(selectedShelf?.upper_shelf_style);
                const shelfLabel = `Upper Shelf ${index} - ${style}`;

                const mesh = addUShapeShelf({
                    width,
                    radius,
                    carcaseTexture,
                    carcaseEdgeTexture,
                    positionZ:
                        height *
                            convertShelfPositionToPercentage(
                                selectedShelf.upper_shelf_position,
                                height
                            ) +
                        upperShelfOffset,
                    shelfOffset: selectedShelf.upper_shelf_offset,
                    positionX,
                    shelfLabel,
                });

                meshes.push(mesh);
            }
        }

        const height2 = upperShelfOffset;

        for (let index = 1; index <= lowerShelvesCount; index++) {
            const selectedShelf = lowerShelves[index - 1];

            if (selectedShelf && selectedShelf.lower_shelf_type === 9) {
                const style = getShelfStyle(selectedShelf?.lower_shelf_style);
                const shelfLabel = `Lower Shelf ${index} - ${style}`;

                const mesh = addUShapeShelf({
                    width,
                    radius,
                    carcaseTexture,
                    carcaseEdgeTexture,
                    positionZ:
                        height2 *
                        convertShelfPositionToPercentage(
                            selectedShelf.lower_shelf_position,
                            height2
                        ),
                    shelfOffset: selectedShelf.lower_shelf_offset,
                    positionX,
                    shelfLabel,
                });

                meshes.push(mesh);
            }
        }

        return meshes;
    };

    const renderUpperAndLowerLeftRightUShapedShelves = () => {
        const upperShelvesCount = values?.upper_shelves
            ? (values?.upper_shelves as unknown as Array<number>)?.length
            : 0;
        const lowerLeftShelvesCount = values?.lower_shelves
            ? (values?.lower_shelves as unknown as Array<number>)?.length
            : 0;
        const lowerRightShelvesCount = values?.shelves
            ? (values?.shelves as unknown as Array<number>)?.length
            : 0;

        const upperShelves =
            values.upper_shelves as unknown as AdvancedUpperShelf[];
        const lowerLeftShelves =
            values.lower_shelves as unknown as AdvancedLowerShelf[];
        const lowerRightShelves = values.shelves as unknown as AdvancedShelf[];

        const shelvesHeightReference = {
            upper_shelves:
                Number(values.cabinet_height) -
                Number(values.cabinet_partition_height),
            lower_shelves: Number(values.cabinet_partition_height),
        };

        const upperShelfOffset = shelvesHeightReference.lower_shelves;
        const height = Number(values.cabinet_height) - upperShelfOffset;

        const partitionWidth = values.cabinet_partition_width
            ? values.cabinet_partition_width < 1
                ? parseFloat(values.cabinet_width?.toString()) *
                  values.cabinet_partition_width
                : parseFloat(values.cabinet_partition_width?.toString())
            : 0;

        const radius = 50;
        const width = Number(values.cabinet_width);
        const positionX = carcaseThickness;

        const meshes: THREE.Mesh[] = [];

        for (let index = 1; index <= upperShelvesCount; index++) {
            const selectedShelf = upperShelves[index - 1];

            if (selectedShelf && selectedShelf.upper_shelf_type === 9) {
                const style = getShelfStyle(selectedShelf?.upper_shelf_style);
                const shelfLabel = `Upper Shelf ${index} - ${style}`;

                const mesh = addUShapeShelf({
                    width,
                    radius,
                    carcaseTexture,
                    carcaseEdgeTexture,
                    positionZ:
                        height *
                            convertShelfPositionToPercentage(
                                selectedShelf.upper_shelf_position,
                                height
                            ) +
                        upperShelfOffset,
                    shelfOffset: selectedShelf.upper_shelf_offset,
                    positionX,
                    shelfLabel,
                });

                meshes.push(mesh);
            }
        }

        const height2 = upperShelfOffset;

        for (let index = 1; index <= lowerLeftShelvesCount; index++) {
            const selectedShelf = lowerLeftShelves[index - 1];

            if (selectedShelf && selectedShelf.lower_shelf_type === 9) {
                const style = getShelfStyle(selectedShelf?.lower_shelf_style);
                const shelfLabel = `BL ${index} - ${style}`;

                const mesh = addUShapeShelf({
                    width: partitionWidth,
                    radius,
                    carcaseTexture,
                    carcaseEdgeTexture,
                    positionZ:
                        height2 *
                        convertShelfPositionToPercentage(
                            selectedShelf.lower_shelf_position,
                            height2
                        ),
                    shelfOffset: selectedShelf.lower_shelf_offset,
                    positionX,
                    shelfLabel,
                });

                meshes.push(mesh);
            }
        }

        for (let index = 1; index <= lowerRightShelvesCount; index++) {
            const selectedShelf = lowerRightShelves[index - 1];

            if (selectedShelf && selectedShelf.shelf_type === 9) {
                const style = getShelfStyle(selectedShelf?.shelf_style);
                const shelfLabel = `BR ${index} - ${style}`;

                const mesh = addUShapeShelf({
                    width: width - partitionWidth,
                    radius,
                    carcaseTexture,
                    carcaseEdgeTexture,
                    positionZ:
                        height2 *
                        convertShelfPositionToPercentage(
                            selectedShelf.shelf_position,
                            height2
                        ),
                    shelfOffset: selectedShelf.shelf_offset,
                    positionX: positionX + partitionWidth,
                    shelfLabel,
                });

                meshes.push(mesh);
            }
        }

        return meshes;
    };

    const renderSingleLShapePanel = () => {
        const leftWidth = Number(values.cabinet_panel_width);
        const rightWidth = Number(values.cabinet_panel_length);
        const leftDepth = Number(values.cabinet_width_depth);
        const rightDepth = Number(values.cabinet_length_depth);
        const radius = Number(values.cabinet_int_radius);

        const mesh = generateLShapeMesh({
            leftWidth,
            leftDepth,
            rightDepth,
            radius,
            rightWidth,
            carcaseTexture,
            carcaseEdgeTexture,
        });

        mesh.position.z = leftWidth;
        mesh.position.x = -rightWidth / 2;
        mesh.rotation.x = (90 * Math.PI) / 180;
        mesh.scale.y = -1;

        const lineSegments = createLineSegments(mesh);

        mesh.add(lineSegments);
        scene.current.add(mesh);
    };

    const generateShelfWithOvenVentMesh = ({
        width,
        depth,
        carcaseTexture,
        carcaseEdgeTexture,
    }: {
        width: number;
        depth: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
    }) => {
        const shape = new THREE.Shape();

        // Panel dimensions

        // Hole dimensions
        const holeWidth = (width - 300) / 2;
        const holeHeight = depth - 80;
        const gap = 100;

        // Hole positions
        const hole1X = 100;
        const hole2X = hole1X + holeWidth + gap;
        const holeY = 45;

        shape.moveTo(0, 0);
        shape.lineTo(width, 0);
        shape.lineTo(width, depth);
        shape.lineTo(0, depth);
        shape.lineTo(0, 0);

        // Create the first hole (a rectangle with dynamic position and size)
        const hole1 = new THREE.Path();
        hole1.moveTo(hole1X, holeY);
        hole1.lineTo(hole1X + holeWidth, holeY);
        hole1.lineTo(hole1X + holeWidth, holeY + holeHeight);
        hole1.lineTo(hole1X, holeY + holeHeight);
        hole1.lineTo(hole1X, holeY);
        shape.holes.push(hole1);

        // Create the second hole (a rectangle with dynamic position and size)
        const hole2 = new THREE.Path();
        hole2.moveTo(hole2X, holeY);
        hole2.lineTo(hole2X + holeWidth, holeY);
        hole2.lineTo(hole2X + holeWidth, holeY + holeHeight);
        hole2.lineTo(hole2X, holeY + holeHeight);
        hole2.lineTo(hole2X, holeY);
        shape.holes.push(hole2);

        const extrudeSettings = {
            steps: 1,
            depth: carcaseThickness,
            bevelEnabled: false,
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        const materials = applyCarcaseMaterial(
            geometry,
            carcaseTexture,
            carcaseEdgeTexture,
            true,
            true
        );

        const position = geometry.attributes.position;
        const faceCount = position.count / 3;

        for (let i = 0; i < faceCount; i++) {
            const vertex1 = new THREE.Vector3().fromBufferAttribute(
                position,
                i * 3
            );
            const vertex2 = new THREE.Vector3().fromBufferAttribute(
                position,
                i * 3 + 1
            );
            const vertex3 = new THREE.Vector3().fromBufferAttribute(
                position,
                i * 3 + 2
            );

            if (vertex1.z === 0 && vertex2.z === 0 && vertex3.z === 0) {
                geometry.addGroup(i * 3, 3, 0); // Front face
            } else if (
                vertex1.z === extrudeSettings.depth &&
                vertex2.z === extrudeSettings.depth &&
                vertex3.z === extrudeSettings.depth
            ) {
                geometry.addGroup(i * 3, 3, 1); // Back face
            } else {
                geometry.addGroup(
                    i * 3,
                    3,
                    [33, 32].includes(geometry.groups?.length) ? 2 : 1
                ); // Sides
            }
        }

        return new THREE.Mesh(geometry, materials);
    };

    const renderShelvesWithOvenVents = () => {
        const ovenOpeningHeight = Number(values.oven_opening_height);
        const height = Number(values.cabinet_height);
        const width = Number(values.cabinet_width) - carcaseThickness;
        const depth = Number(values.cabinet_depth) - carcaseThickness;
        let ventLocation = (height - ovenOpeningHeight) / 2 - carcaseThickness;

        if (Boolean(values.drawers)) {
            ventLocation = height - ovenOpeningHeight - carcaseThickness;
        }

        if (variables?.isWallOvenProduct) {
            ventLocation =
                Number(values.cabinet_total_drawer_height) - carcaseThickness;
        }

        const vents = [{locationZ: 0}, {locationZ: ventLocation}];
        const meshes: THREE.Mesh[] = [];

        vents.forEach((vent) => {
            const mesh = generateShelfWithOvenVentMesh({
                width,
                depth,
                carcaseTexture,
                carcaseEdgeTexture,
            });

            mesh.position.z = vent.locationZ;
            mesh.position.x = carcaseThickness;

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        });

        return meshes;
    };

    const addLabelMeshForSinglePanel = (
        shelfLabel: string,
        parentMesh: THREE.Mesh,
        position: {
            x: number;
            y: number;
            z: number;
        },
        rotation?: number,
        shouldCenterLabel?: boolean
    ) => {
        const fontLoader = new FontLoader();

        fontLoader.load(
            '/templates/3D/fonts/Poppins SemiBold_Regular.json',
            (font) => {
                const textGeometry = new TextGeometry(shelfLabel, {
                    font,
                    size: 4,
                    height: 1,
                    depth: 0.5,
                });

                const textMesh = new THREE.Mesh(
                    textGeometry,
                    new THREE.MeshStandardMaterial({
                        color: '#333',
                    })
                );

                const scale = 7;
                textMesh.scale.set(scale, scale, scale);

                const boundingBox = new THREE.Box3().setFromObject(textMesh);
                const meshWidth = boundingBox.max.x - boundingBox.min.x;

                if (rotation) textMesh.rotation.z = rotation;

                const box = new THREE.Box3().setFromObject(parentMesh);
                const meshXParent = box.max.x - box.min.x;
                const positionX = meshXParent / 2 - meshWidth / 2;

                textMesh.position.set(
                    shouldCenterLabel ? positionX : position.x,
                    position.y,
                    position.z
                );

                parentMesh.add(textMesh);
            }
        );
    };

    const renderSingleUShapedPanel = () => {
        const radius = 50;

        const width = Number(values.cabinet_panel_width);
        const width1 = Number(values.cabinet_width1);
        const height = 900;
        const depth = Number(values.cabinet_panel_length);
        const length1 = Number(values.cabinet_length1);
        const length2 = Number(values.cabinet_length2);
        const cutoutWidth = depth - (length1 + length2);
        const cutoutHeight = depth * 0.5;

        const mesh = generateUShapeMesh({
            width: depth,
            depth: width,
            cutoutWidth,
            cutoutHeight,
            radius,
            carcaseTexture,
            carcaseEdgeTexture,
            complexRadii: {
                exteriorRadius: Number(values.cabinet_ext_radius),
                interiorRadius: Number(values.cabinet_int_radius),
            },
            sides: {
                left: length1,
                right: length2,
            },
            customCutoutHeight: width1,
        });

        addLabelMeshForSinglePanel(
            'Length',
            mesh,
            {
                x: depth / 2,
                y: width + 20,
                z: 0,
            },
            undefined,
            true
        );

        addLabelMeshForSinglePanel(
            'Length 1',
            mesh,
            {
                x: length1 / 2 - 80,
                y: -40,
                z: 0,
            },
            undefined
        );

        addLabelMeshForSinglePanel(
            'Length 2',
            mesh,
            {
                x: depth - length2 / 2 - 80,
                y: -40,
                z: 0,
            },
            undefined
        );

        addLabelMeshForSinglePanel(
            'Width',
            mesh,
            {
                x: -20,
                y: width / 2 - 70,
                z: 0,
            },
            (90 * Math.PI) / 180
        );

        addLabelMeshForSinglePanel(
            'Width 1',
            mesh,
            {
                x: length1 + 40,
                y: width1 / 2 - 70,
                z: 0,
            },
            (90 * Math.PI) / 180
        );

        const lineSegments = createLineSegments(mesh);

        mesh.position.set(
            -(width / 2),
            -depth / 2 + carcaseThickness / 3 + 1,
            height * convertShelfPositionToPercentage(0.3, height)
        );

        mesh.add(lineSegments);
        scene.current?.add(mesh);
    };

    const generateCornerShapeMesh = ({
        leftWidth,
        leftDepth,
        rightDepth,
        rightWidth,
        carcaseTexture,
        carcaseEdgeTexture,
        thickness = carcaseThickness,
    }: {
        leftWidth: number;
        leftDepth: number;
        rightDepth: number;
        rightWidth: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
        thickness?: number;
    }) => {
        // Create the shape
        const shape = new THREE.Shape();

        // Start at the bottom-left corner
        shape.moveTo(0, 0);

        // Draw the left vertical line
        shape.lineTo(0, leftWidth);

        // Draw the top horizontal line to the right (end of left width)
        shape.lineTo(leftDepth, leftWidth);

        // Draw the remaining horizontal line to the right
        shape.lineTo(rightWidth, rightDepth);

        // Draw the right vertical line upwards
        shape.lineTo(rightWidth, 0);

        // Close the shape by drawing the bottom horizontal line to the left
        shape.lineTo(0, 0);

        const extrudeSettings = {
            steps: 1,
            depth: thickness,
            bevelEnabled: false,
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        const materials = applyCarcaseMaterial(
            geometry,
            carcaseTexture,
            carcaseEdgeTexture,
            false,
            variables?.isClipProduct ? true : false,
            variables?.isClipProduct ? horizontalGrainExt : horizontalGrainCarc
        );

        // Apply materials to faces
        for (let i = 0; i < geometry.groups.length; i++) {
            geometry.groups[Number(i)].materialIndex = i % 2; // Alternate materials
        }

        // Create the mesh
        return new THREE.Mesh(geometry, materials);
    };

    const generateFfRailOnFlatMesh = ({
        leftWidth,
        leftDepth,
        rightDepth,
        rightWidth,
        carcaseTexture,
        carcaseEdgeTexture,
    }: {
        leftWidth: number;
        leftDepth: number;
        rightDepth: number;
        rightWidth: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
    }) => {
        // Create the shape
        const shape = new THREE.Shape();

        const railWidth = 120;

        shape.moveTo(leftDepth - railWidth, carcaseThickness);
        shape.lineTo(leftDepth, carcaseThickness);
        shape.lineTo(rightWidth, leftWidth - rightDepth);
        shape.lineTo(rightWidth, leftWidth - rightDepth + railWidth);
        shape.lineTo(leftDepth - railWidth, carcaseThickness);

        const extrudeSettings = {
            steps: 1,
            depth: carcaseThickness,
            bevelEnabled: false,
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        const materials = applyCarcaseMaterial(
            geometry,
            carcaseTexture,
            carcaseEdgeTexture
        );

        // Apply materials to faces
        for (let i = 0; i < geometry.groups.length; i++) {
            geometry.groups[Number(i)].materialIndex = i % 2; // Alternate materials
        }

        // Create the mesh
        return new THREE.Mesh(geometry, materials);
    };

    const renderCornerShapedPanel = () => {
        const [widthOffset] = getCornerShapeOffsets();
        const [widthOffsetLShape] = getLShapeOffsets();
        const height = Number(values.cabinet_height);
        const rightWidth =
            values.cabinet_right_width - widthOffset + carcaseThickness * 2 + 2;
        const leftWidth = Number(values.cabinet_left_width) + 2;

        const shelvesCount = Number(values.simple_shelves_amount);
        const advancedShelves = values.shelves as unknown as AdvancedShelf[];

        const meshes: THREE.Mesh[] = [];

        if (isFfRailOnFlat) {
            const mesh = generateFfRailOnFlatMesh({
                leftWidth,
                leftDepth: values.cabinet_left_depth - carcaseThickness,
                rightDepth: Number(values.cabinet_right_depth),
                rightWidth,
                carcaseTexture,
                carcaseEdgeTexture,
            });

            mesh.position.set(
                carcaseThickness,
                -carcaseThickness,
                height - carcaseThickness
            );

            const lineSegments = createLineSegments(mesh);
            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        for (
            let index = 0;
            index <= shelvesCount + (isFfSolidTop ? 1 : 0);
            index++
        ) {
            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth = values.cabinet_left_depth - carcaseThickness;
            let shelvePosition = (height / (shelvesCount + 1)) * index;
            let style = '';

            const isCornerSolidTopSelected = index === shelvesCount + 1;
            const shouldApplyOffset = index !== 0 && index !== shelvesCount + 1;

            const selectedShelf = advancedShelves[index - 1];

            if (selectedShelf) {
                shelvePosition =
                    height *
                    convertShelfPositionToPercentage(
                        selectedShelf.shelf_position,
                        height
                    );

                rightDepth =
                    values.cabinet_right_depth -
                    (shouldApplyOffset ? selectedShelf.shelf_offset : 0);
                leftDepth =
                    values.cabinet_left_depth -
                    carcaseThickness -
                    (shouldApplyOffset ? selectedShelf.shelf_offset : 0);

                style = getShelfStyle(selectedShelf?.shelf_style);
            }

            if (index === shelvesCount + 1) shelvePosition = height;

            let mesh: THREE.Mesh = null;

            if (!values.cabinet_simple_shelves && shouldApplyOffset) {
                mesh = generateLShapeMesh({
                    leftWidth,
                    leftDepth,
                    rightDepth,
                    radius: 50,
                    rightWidth:
                        values.cabinet_right_width -
                        widthOffsetLShape -
                        carcaseThickness,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            } else {
                mesh = generateCornerShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - (shouldApplyOffset ? 6 : 1),
                    rightDepth,
                    rightWidth,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            }

            const positionY = leftWidth - 2;

            mesh.position.set(
                carcaseThickness,
                positionY,
                shelvePosition -
                    (isCornerSolidTopSelected ? carcaseThickness : 0) +
                    1
            );
            mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const renderCornerShapePanelForPantry = () => {
        const [widthOffset] = getCornerShapeOffsets();
        const [widthOffsetLShape] = getCornerLShapeOffsets();

        const upperShelvesCount = values?.upper_shelves
            ? (values?.upper_shelves as unknown as Array<number>)?.length
            : 0;
        const lowerShelvesCount = values?.lower_shelves
            ? (values?.lower_shelves as unknown as Array<number>)?.length
            : 0;

        const upperShelves =
            values.upper_shelves as unknown as AdvancedUpperShelf[];
        const lowerShelves =
            values.lower_shelves as unknown as AdvancedLowerShelf[];
        const shelvesHeightReference = {
            upper_shelves:
                Number(values.cabinet_height) -
                Number(values.cabinet_partition_height),
            lower_shelves: Number(values.cabinet_partition_height),
        };

        const upperShelfOffset = shelvesHeightReference.lower_shelves;
        const height = Number(values.cabinet_height) - upperShelfOffset;

        const rightWidth = values.cabinet_right_width - widthOffset;
        const leftWidth = Number(values.cabinet_left_width) + 2;

        const meshes: THREE.Mesh[] = [];

        for (let index = 0; index <= upperShelvesCount + 1; index++) {
            const selectedShelf = upperShelves[index - 1];
            const shouldApplyOffset =
                index !== 0 && index !== upperShelvesCount + 1;
            const isCornerSolidTopSelected = index === upperShelvesCount + 1;
            let shelvePosition = height;
            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth =
                Number(values.cabinet_left_depth) - carcaseThickness;
            let style = '';

            if (selectedShelf) {
                style = getShelfStyle(selectedShelf?.upper_shelf_style);

                shelvePosition =
                    height *
                    convertShelfPositionToPercentage(
                        selectedShelf.upper_shelf_position,
                        height
                    );

                rightDepth =
                    values.cabinet_right_depth -
                    (shouldApplyOffset ? selectedShelf.upper_shelf_offset : 0);
                leftDepth =
                    values.cabinet_left_depth -
                    carcaseThickness -
                    (shouldApplyOffset ? selectedShelf.upper_shelf_offset : 0);
            }

            if (index === 0) {
                shelvePosition = 0;
            }

            let mesh: THREE.Mesh = null;
            const positionX = carcaseThickness;
            const positionY = leftWidth - 2;

            if (shouldApplyOffset || shelvePosition === 0) {
                mesh = generateLShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    radius: 50,
                    rightWidth:
                        values.cabinet_right_width -
                        widthOffsetLShape -
                        carcaseThickness,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            } else {
                mesh = generateCornerShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    rightWidth: rightWidth + carcaseThickness * 2 + 2,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            }

            mesh.position.set(
                positionX,
                positionY,
                shelvePosition -
                    (isCornerSolidTopSelected ? carcaseThickness : 0) +
                    1 +
                    upperShelfOffset
            );

            mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Upper Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            } else if (index === 0) {
                addLabelMesh(
                    'Horizontal Partition',
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        const height2 = upperShelfOffset;

        for (
            let index = variables?.is2Part ? 0 : 1;
            index <=
            lowerShelvesCount + (variables?.noCornerBaseBottom ? 0 : 1);
            index++
        ) {
            const selectedShelf = lowerShelves[index - 1];
            const shouldApplyOffset =
                index !== 0 && index !== lowerShelvesCount + 1;
            const isCornerSolidTopSelected = index === upperShelvesCount + 1;
            let shelvePosition = 0;
            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth =
                Number(values.cabinet_left_depth) - carcaseThickness;
            let style = '';

            if (selectedShelf) {
                style = getShelfStyle(selectedShelf?.lower_shelf_style);

                shelvePosition =
                    height2 *
                    convertShelfPositionToPercentage(
                        selectedShelf.lower_shelf_position,
                        height2
                    );

                rightDepth =
                    values.cabinet_right_depth -
                    (shouldApplyOffset ? selectedShelf.lower_shelf_offset : 0);
                leftDepth =
                    values.cabinet_left_depth -
                    carcaseThickness -
                    (shouldApplyOffset ? selectedShelf.lower_shelf_offset : 0);
            }

            let mesh: THREE.Mesh = null;
            const positionX = carcaseThickness;
            const positionY = leftWidth - 2;

            if (shouldApplyOffset || index === 0) {
                mesh = generateLShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    radius: 50,
                    rightWidth:
                        values.cabinet_right_width -
                        widthOffsetLShape -
                        carcaseThickness,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            } else {
                mesh = generateCornerShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    rightWidth: rightWidth + carcaseThickness * 2 + 2,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            }

            mesh.position.set(
                positionX,
                positionY,
                index === 0
                    ? height2 - carcaseThickness
                    : shelvePosition -
                          (isCornerSolidTopSelected ? carcaseThickness : 0) +
                          1
            );
            mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Lower Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const renderCornerShapePanelFor3PartPantry = () => {
        const [widthOffset] = getCornerShapeOffsets();
        const [widthOffsetLShape] = getCornerLShapeOffsets();

        const upperShelvesCount = values?.upper_shelves
            ? (values?.upper_shelves as unknown as Array<number>)?.length
            : 0;
        const middleShelvesCount = values?.middle_shelves
            ? (values?.middle_shelves as unknown as Array<number>)?.length
            : 0;
        const lowerShelvesCount = values?.lower_shelves
            ? (values?.lower_shelves as unknown as Array<number>)?.length
            : 0;

        const upperShelves =
            values.upper_shelves as unknown as AdvancedUpperShelf[];
        const middleShelves =
            values.middle_shelves as unknown as AdvancedMiddleShelf[];
        const lowerShelves =
            values.lower_shelves as unknown as AdvancedLowerShelf[];

        const cabinetUpperPartitionHeight =
            Number(values.cabinet_upper_partition_height) > 1
                ? Number(values.cabinet_upper_partition_height) /
                  Number(values.cabinet_height)
                : Number(values.cabinet_upper_partition_height);
        const cabinetLowerPartitionHeight =
            Number(values.cabinet_lower_partition_height) > 1
                ? Number(values.cabinet_lower_partition_height) /
                  Number(values.cabinet_height)
                : Number(values.cabinet_lower_partition_height);

        const upperShelfOffset =
            cabinetUpperPartitionHeight * Number(values.cabinet_height);
        const lowerShelfOffset =
            cabinetLowerPartitionHeight * Number(values.cabinet_height);

        const height = Number(values.cabinet_height) - upperShelfOffset;

        const rightWidth = values.cabinet_right_width - widthOffset;
        const leftWidth = Number(values.cabinet_left_width) + 2;

        const positionX = carcaseThickness;
        const positionY = leftWidth - 2;

        const meshes: THREE.Mesh[] = [];

        for (let index = 0; index <= upperShelvesCount + 1; index++) {
            const selectedShelf = upperShelves[index - 1];
            const shouldApplyOffset =
                index !== 0 && index !== upperShelvesCount + 1;
            const isCornerSolidTopSelected = index === upperShelvesCount + 1;
            let shelvePosition = Number(values.cabinet_height);
            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth =
                Number(values.cabinet_left_depth) - carcaseThickness;
            let style = '';

            if (selectedShelf) {
                style = getShelfStyle(selectedShelf?.upper_shelf_style);

                shelvePosition =
                    height *
                        convertShelfPositionToPercentage(
                            selectedShelf.upper_shelf_position,
                            height
                        ) +
                    upperShelfOffset;

                rightDepth =
                    values.cabinet_right_depth -
                    (shouldApplyOffset ? selectedShelf.upper_shelf_offset : 0);
                leftDepth =
                    values.cabinet_left_depth -
                    carcaseThickness -
                    (shouldApplyOffset ? selectedShelf.upper_shelf_offset : 0);
            }

            if (index === 0) {
                shelvePosition = upperShelfOffset;
            }

            let mesh: THREE.Mesh = null;

            if (shouldApplyOffset || index === 0) {
                mesh = generateLShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    radius: 50,
                    rightWidth:
                        values.cabinet_right_width -
                        widthOffsetLShape -
                        carcaseThickness,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            } else {
                mesh = generateCornerShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    rightWidth: rightWidth + carcaseThickness * 2 + 2,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            }

            mesh.position.set(
                positionX,
                positionY,
                shelvePosition -
                    (isCornerSolidTopSelected ? carcaseThickness : 0) +
                    1
            );
            mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Upper Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            } else if (index === 0) {
                addLabelMesh(
                    'Upper Partition',
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        const height2 = upperShelfOffset - lowerShelfOffset;

        for (let index = 0; index <= middleShelvesCount + 1; index++) {
            const selectedShelf = middleShelves[index - 1];
            const shouldApplyOffset =
                index !== 0 && index !== middleShelvesCount + 1;
            let shelvePosition = lowerShelfOffset;
            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth =
                Number(values.cabinet_left_depth) - carcaseThickness;
            let style = '';

            if (selectedShelf) {
                style = getShelfStyle(selectedShelf?.middle_shelf_style);

                shelvePosition =
                    height2 *
                        convertShelfPositionToPercentage(
                            selectedShelf.middle_shelf_position,
                            height2
                        ) +
                    lowerShelfOffset;

                rightDepth =
                    values.cabinet_right_depth -
                    (shouldApplyOffset ? selectedShelf.middle_shelf_offset : 0);
                leftDepth =
                    values.cabinet_left_depth -
                    carcaseThickness -
                    (shouldApplyOffset ? selectedShelf.middle_shelf_offset : 0);
            }

            if (index === 0) {
                shelvePosition = upperShelfOffset - carcaseThickness;
            }

            let mesh: THREE.Mesh = null;

            if (
                shouldApplyOffset ||
                index === 0 ||
                index === middleShelvesCount + 1
            ) {
                mesh = generateLShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    radius: 50,
                    rightWidth:
                        values.cabinet_right_width -
                        widthOffsetLShape -
                        carcaseThickness,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            } else {
                mesh = generateCornerShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    rightWidth: rightWidth + carcaseThickness * 2 + 2,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            }

            mesh.position.set(positionX, positionY, shelvePosition + 1);
            mesh.scale.y = -1;

            if (shouldApplyOffset && index !== middleShelvesCount + 1) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Middle Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            } else if (index === middleShelvesCount + 1) {
                addLabelMesh(
                    'Lower Partition',
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        const height3 = lowerShelfOffset;

        for (
            let index = 0;
            index <=
            lowerShelvesCount + (variables?.noCornerBaseBottom ? 0 : 1);
            index++
        ) {
            const selectedShelf = lowerShelves[index - 1];
            const shouldApplyOffset =
                index !== 0 && index !== lowerShelvesCount + 1;
            const isCornerSolidTopSelected = index === upperShelvesCount + 1;
            let shelvePosition = 0;
            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth =
                Number(values.cabinet_left_depth) - carcaseThickness;
            let style = '';

            if (selectedShelf) {
                style = getShelfStyle(selectedShelf?.lower_shelf_style);

                shelvePosition =
                    height3 *
                    convertShelfPositionToPercentage(
                        selectedShelf.lower_shelf_position,
                        height3
                    );

                rightDepth =
                    values.cabinet_right_depth -
                    (shouldApplyOffset ? selectedShelf.lower_shelf_offset : 0);
                leftDepth =
                    values.cabinet_left_depth -
                    carcaseThickness -
                    (shouldApplyOffset ? selectedShelf.lower_shelf_offset : 0);
            }

            let mesh: THREE.Mesh = null;

            if (shouldApplyOffset || index === 0) {
                mesh = generateLShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    radius: 50,
                    rightWidth:
                        values.cabinet_right_width -
                        widthOffsetLShape -
                        carcaseThickness,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            } else {
                mesh = generateCornerShapeMesh({
                    leftWidth,
                    leftDepth: leftDepth - 1,
                    rightDepth,
                    rightWidth: rightWidth + carcaseThickness * 2 + 2,
                    carcaseTexture,
                    carcaseEdgeTexture,
                });
            }

            mesh.position.set(
                positionX,
                positionY,
                index === 0
                    ? height3 - carcaseThickness
                    : shelvePosition -
                          (isCornerSolidTopSelected ? carcaseThickness : 0) +
                          1
            );
            mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Lower Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const generateRadiusShapeMesh = ({
        width,
        depth,
        texture,
        edgeTexture,
    }: {
        width: number;
        depth: number;
        texture: THREE.Texture;
        edgeTexture: THREE.Texture;
    }) => {
        const shape = new THREE.Shape();

        // Define the control points and radii
        const startX = depth;
        const startY = 0;
        const controlX = depth;
        const controlY = width; // Middle control point
        const endX = 0;
        const endY = width;

        // Move to the starting point
        shape.moveTo(startX, startY);

        // Create the non-uniform quarter arc using quadraticCurveTo
        shape.quadraticCurveTo(controlX, controlY, endX, endY);

        // Close the shape (optional, if you want to fill the shape)
        shape.lineTo(0, 0);
        shape.lineTo(startX, startY);

        const extrudeSettings = {
            steps: 1,
            depth: exteriorThickness,
            bevelEnabled: false,
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        const materials = applyCarcaseMaterial(
            geometry,
            texture,
            edgeTexture,
            false,
            !variables?.isLeftRadius,
            horizontalGrainExt
        );

        // Apply materials to faces
        for (let i = 0; i < geometry.groups.length; i++) {
            geometry.groups[Number(i)].materialIndex = i % 2; // Alternate materials
        }

        // Create the mesh
        return new THREE.Mesh(geometry, materials);
    };

    const renderClipPanel = () => {
        const height = Number(values.cabinet_height);
        const depthPercentage = 0.4;

        const shelvesCount = Number(values.simple_shelves_amount);
        const advancedShelves = values.shelves as unknown as AdvancedShelf[];

        const meshes: THREE.Mesh[] = [];
        const depth = Number(values.cabinet_depth);
        const width = Number(values.cabinet_width);

        for (let index = 0; index <= shelvesCount + 1; index++) {
            const rightDepth = depth * depthPercentage;
            const leftDepth = depth * depthPercentage;
            let rightWidth = width - exteriorThickness;
            let leftWidth = depth - exteriorThickness;
            let shelvePosition = (height / (shelvesCount + 1)) * index;
            let style = '';

            const shouldApplyOffset = index !== 0 && index !== shelvesCount + 1;
            const selectedShelf = advancedShelves[index - 1];
            let offset = 0;

            if (selectedShelf) {
                shelvePosition =
                    height *
                    convertShelfPositionToPercentage(
                        selectedShelf.shelf_position,
                        height
                    );

                rightWidth =
                    width -
                    exteriorThickness -
                    (shouldApplyOffset ? selectedShelf.shelf_offset : 0);
                leftWidth =
                    depth -
                    exteriorThickness -
                    (shouldApplyOffset ? selectedShelf.shelf_offset : 0);

                style = getShelfStyle(selectedShelf?.shelf_style);
                offset = selectedShelf.shelf_offset;
            }

            if (index === shelvesCount + 1)
                shelvePosition = height - exteriorThickness;

            const mesh = generateCornerShapeMesh({
                leftWidth,
                leftDepth: leftDepth - (shouldApplyOffset ? 6 : 1),
                rightDepth,
                rightWidth,
                carcaseTexture: exteriorTexture,
                carcaseEdgeTexture: exteriorEdgeTexture,
                thickness: exteriorThickness,
            });

            const positionY = leftWidth;

            mesh.position.set(
                !variables?.isLeftClip ? exteriorThickness : width,
                positionY + (shouldApplyOffset ? offset : 0),
                shelvePosition
            );

            mesh.scale.y = -1;
            if (variables?.isLeftClip) mesh.scale.x = -1;

            if (shouldApplyOffset) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(
                    shelfLabel,
                    exteriorThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const renderRadiusPanel = () => {
        const height = Number(values.cabinet_height);

        const shelvesCount = Number(values.simple_shelves_amount);
        const advancedShelves = values.shelves as unknown as AdvancedShelf[];

        const meshes: THREE.Mesh[] = [];
        const depth = Number(values.cabinet_depth) - exteriorThickness;
        const width = Number(values.cabinet_width) - exteriorThickness;

        for (let index = 0; index <= shelvesCount + 1; index++) {
            let shelvePosition = (height / (shelvesCount + 1)) * index;
            let style = '';

            const shouldApplyOffset = index !== 0 && index !== shelvesCount + 1;
            const selectedShelf = advancedShelves[index - 1];
            let offset = 0;

            if (selectedShelf) {
                shelvePosition =
                    height *
                    convertShelfPositionToPercentage(
                        selectedShelf.shelf_position,
                        height
                    );

                style = getShelfStyle(selectedShelf?.shelf_style);
                offset = selectedShelf.shelf_offset;
            }

            if (index === shelvesCount + 1)
                shelvePosition = height - exteriorThickness;

            const mesh = generateRadiusShapeMesh({
                width: (!variables?.isLeftRadius ? depth : width) - offset,
                depth: (!variables?.isLeftRadius ? width : depth) - offset,
                texture: exteriorTexture,
                edgeTexture: exteriorEdgeTexture,
            });

            const positionY = depth;

            mesh.position.set(
                !variables?.isLeftRadius
                    ? exteriorThickness
                    : width + exteriorThickness,
                positionY,
                shelvePosition
            );

            if (variables?.isLeftRadius) {
                mesh.rotation.z = Math.PI / 2;
                mesh.scale.x = -1;
            } else mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(shelfLabel, exteriorThickness, mesh, depth / 2);
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const renderLShapedPanelForPantry = () => {
        const upperShelvesCount = values?.upper_shelves
            ? (values?.upper_shelves as unknown as Array<number>)?.length
            : 0;
        const lowerShelvesCount = values?.lower_shelves
            ? (values?.lower_shelves as unknown as Array<number>)?.length
            : 0;

        const upperShelves =
            values.upper_shelves as unknown as AdvancedUpperShelf[];
        const lowerShelves =
            values.lower_shelves as unknown as AdvancedLowerShelf[];
        const shelvesHeightReference = {
            upper_shelves:
                parseFloat(values.cabinet_height?.toString()) -
                values.cabinet_partition_height,
            lower_shelves: values.cabinet_partition_height,
        };

        const upperShelfOffset = shelvesHeightReference.lower_shelves;
        const height = Number(values.cabinet_height) - upperShelfOffset;

        const meshes: THREE.Mesh[] = [];
        const radius = 20;
        const rightWidth =
            values.cabinet_right_width - radius - carcaseThickness;
        const leftWidth = Number(values.cabinet_left_width) + 2;

        for (let index = 0; index <= upperShelvesCount + 1; index++) {
            const selectedShelf = upperShelves[index - 1];
            const leftDepthValue =
                Number(values.cabinet_left_depth) - carcaseThickness - 1;

            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth = leftDepthValue;
            let shelvePosition =
                Number(values.cabinet_height) - carcaseThickness;
            const shouldApplyOffset =
                index !== 0 && index !== upperShelvesCount + 1;

            if (
                selectedShelf &&
                Number(selectedShelf.upper_shelf_type) === 14
            ) {
                rightDepth =
                    Number(values.cabinet_right_depth) -
                    (shouldApplyOffset ? selectedShelf.upper_shelf_offset : 0);
                leftDepth =
                    leftDepthValue -
                    (shouldApplyOffset ? selectedShelf.upper_shelf_offset : 0);

                shelvePosition =
                    height *
                        convertShelfPositionToPercentage(
                            selectedShelf.upper_shelf_position,
                            height
                        ) +
                    upperShelfOffset;
            }

            const isHorizontalParition = index === upperShelvesCount + 1;

            if (isHorizontalParition) {
                shelvePosition = upperShelfOffset;
            }

            const mesh = generateLShapeMesh({
                leftWidth,
                leftDepth,
                rightDepth,
                radius,
                rightWidth,
                carcaseTexture,
                carcaseEdgeTexture,
            });

            const positionX = carcaseThickness;

            mesh.position.set(positionX, leftWidth - 2, shelvePosition);

            mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const style = getShelfStyle(selectedShelf?.upper_shelf_style);
                const shelfLabel = `Upper Shelf ${index} - ${style}`;

                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            if (isHorizontalParition) {
                addLabelMesh(
                    'Horizontal Partition',
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        const height2 = upperShelfOffset;

        for (let index = 0; index <= lowerShelvesCount; index++) {
            const selectedShelf = lowerShelves[index - 1];
            const leftDepthValue =
                Number(values.cabinet_left_depth) - carcaseThickness - 1;

            let rightDepth = Number(values.cabinet_right_depth);
            let leftDepth = leftDepthValue;
            let shelvePosition = 0;
            const shouldApplyOffset = index !== 0;

            if (
                selectedShelf &&
                Number(selectedShelf.lower_shelf_type) === 14
            ) {
                rightDepth =
                    Number(values.cabinet_right_depth) -
                    (shouldApplyOffset ? selectedShelf.lower_shelf_offset : 0);
                leftDepth =
                    leftDepthValue -
                    (shouldApplyOffset ? selectedShelf.lower_shelf_offset : 0);

                shelvePosition =
                    height2 *
                    convertShelfPositionToPercentage(
                        selectedShelf.lower_shelf_position,
                        height2
                    );
            }

            const mesh = generateLShapeMesh({
                leftWidth,
                leftDepth,
                rightDepth,
                radius,
                rightWidth,
                carcaseTexture,
                carcaseEdgeTexture,
            });

            const positionX = carcaseThickness;

            mesh.position.set(positionX, leftWidth - 2, shelvePosition);

            mesh.scale.y = -1;

            if (shouldApplyOffset) {
                const style = getShelfStyle(selectedShelf?.lower_shelf_style);
                const shelfLabel = `Lower Shelf ${index} - ${style}`;

                addLabelMesh(
                    shelfLabel,
                    carcaseThickness,
                    mesh,
                    rightDepth,
                    -(rightWidth - leftDepth) / 2
                );
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const generateRangehoodCutoutShapeMesh = ({
        width,
        depth,
        carcaseTexture,
        carcaseEdgeTexture,
        thickness = carcaseThickness,
    }: {
        width: number;
        depth: number;
        carcaseTexture: THREE.Texture;
        carcaseEdgeTexture: THREE.Texture;
        thickness?: number;
    }) => {
        const ventRightPositionX =
            width / 2 + DEFAULT_VENT_FRONT_WIDTH / 2 - carcaseThickness / 2;
        const ventLeftPositionX =
            width / 2 - DEFAULT_VENT_FRONT_WIDTH / 2 - carcaseThickness / 2;
        const ventFrontPositionY =
            depth - DEFAULT_VENT_WIDTH - carcaseThickness;
        // Create the shape
        const shape = new THREE.Shape();

        shape.moveTo(0, 0);

        shape.lineTo(width, 0);
        shape.lineTo(width, depth);
        shape.lineTo(ventRightPositionX, depth);
        shape.lineTo(ventRightPositionX, ventFrontPositionY);
        shape.lineTo(ventLeftPositionX, ventFrontPositionY);
        shape.lineTo(ventLeftPositionX, depth);
        shape.lineTo(0, depth);

        // Close the shape by drawing the bottom horizontal line to the left
        shape.lineTo(0, 0);

        const extrudeSettings = {
            steps: 1,
            depth: thickness,
            bevelEnabled: false,
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        const materials = applyCarcaseMaterial(
            geometry,
            carcaseTexture,
            carcaseEdgeTexture,
            false,
            true
        );

        // Apply materials to faces
        for (let i = 0; i < geometry.groups.length; i++) {
            geometry.groups[Number(i)].materialIndex = i % 2; // Alternate materials
        }

        // Create the mesh
        return new THREE.Mesh(geometry, materials);
    };

    const renderShelvesWithRangehoodCutout = () => {
        const rangehoodOpeningHeight = Number(values.rangehood_opening_height);
        const height = Number(values.cabinet_height) - rangehoodOpeningHeight;
        const width = Number(values.cabinet_width) - carcaseThickness;
        const depth = Number(values.cabinet_depth) - carcaseThickness;

        const shelvesCount = Number(values.simple_shelves_amount);
        const advancedShelves = values.shelves as unknown as AdvancedShelf[];

        const meshes: THREE.Mesh[] = [];

        for (let index = 0; index < shelvesCount + 2; index++) {
            let shelvePosition =
                (height / (shelvesCount + 1)) * index + rangehoodOpeningHeight;
            let style = '';

            const isLsSolidTopSelected = index === shelvesCount + 1;
            const applyLabel = index !== 0 && index !== shelvesCount + 1;

            if (!values.cabinet_simple_shelves) {
                const selectedShelf = advancedShelves[index - 1];

                if (selectedShelf) {
                    shelvePosition =
                        height *
                            convertShelfPositionToPercentage(
                                selectedShelf.shelf_position,
                                height
                            ) +
                        rangehoodOpeningHeight;

                    style =
                        Number(selectedShelf?.shelf_style) === 0
                            ? 'Fixed'
                            : 'Adj';
                }
            }

            const mesh = generateRangehoodCutoutShapeMesh({
                width,
                depth,
                carcaseTexture,
                carcaseEdgeTexture,
            });

            mesh.position.set(
                carcaseThickness,
                0,
                shelvePosition - (isLsSolidTopSelected ? carcaseThickness : 0)
            );

            if (applyLabel) {
                const simpleShelvesStyle = getShelfStyle(
                    values.simple_shelf_type
                );
                const shelfLabel = `Shelf ${index} - ${
                    style ? style : simpleShelvesStyle
                }`;
                addLabelMesh(shelfLabel, carcaseThickness, mesh, 20);
            }

            const lineSegments = createLineSegments(mesh);

            mesh.add(lineSegments);
            meshes.push(mesh);
        }

        return meshes;
    };

    const shapedParts: {
        condition: boolean;
        callback: () => THREE.Mesh[] | void;
    }[] = [
        {
            condition: variables.hasLShapedShelves,
            callback: renderLShapedPanel,
        },
        {
            condition: !(
                typeof values.lower_shelves !== 'undefined' ||
                typeof values.upper_shelves !== 'undefined'
            ),
            callback: renderUShapedPanel,
        },
        {
            condition: variables.isReturnProduct,
            callback: renderLShapedPanelForReturn,
        },
        {
            condition: !(
                !values?.upper_shelves ||
                values?.shelves ||
                variables?.isReturnProduct
            ),
            callback: renderUpperAndLowerUShapeShelves,
        },
        {
            condition: Boolean(values?.upper_shelves && values?.shelves),
            callback: renderUpperAndLowerLeftRightUShapedShelves,
        },
        {
            condition: variables.isLShapePanel,
            callback: renderSingleLShapePanel,
        },
        {
            condition:
                variables.isReturnProduct && variables?.shelfType === 'PANTRY',
            callback: renderLShapedPanelForPantryReturn,
        },
        {
            condition: Boolean(values.oven_opening_height),
            callback: renderShelvesWithOvenVents,
        },
        {
            condition: variables.isUShapedPanel,
            callback: renderSingleUShapedPanel,
        },
        {
            condition: variables.hasCornerShapedShelves,
            callback: renderCornerShapedPanel,
        },
        {
            condition:
                !(
                    !values?.upper_shelves ||
                    values?.shelves ||
                    variables?.isReturnProduct
                ) &&
                variables?.isCornerProduct &&
                !Boolean(values.middle_shelves),
            callback: renderCornerShapePanelForPantry,
        },
        {
            condition:
                !(
                    !values?.upper_shelves ||
                    values?.shelves ||
                    variables?.isReturnProduct
                ) &&
                variables?.isCornerProduct &&
                Boolean(values.middle_shelves),
            callback: renderCornerShapePanelFor3PartPantry,
        },
        {
            condition: variables.isClipProduct,
            callback: renderClipPanel,
        },
        {
            condition: variables.isRadiusProduct,
            callback: renderRadiusPanel,
        },
        {
            condition: variables.isLShapedPantry,
            callback: renderLShapedPanelForPantry,
        },
        {
            condition: variables.hasRangehoodVent,
            callback: renderShelvesWithRangehoodCutout,
        },
    ];

    return {
        shapedParts,
    };
};

export default useShapedPanel;
