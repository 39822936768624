import React, {createContext, useContext} from 'react';
import {Preview3DComponentProps, PreviewFormValues} from 'Preview3D/types';
import {ProductDataStore} from 'components/customer/Preview3D/usePreview3DData';
import {Preview3dComponentWithProvider} from 'components/customer/Preview3D/components/Preview3dComponentWithProvider';

interface Preview3DContextProps {
    values?: PreviewFormValues;
    productDataStore?: ProductDataStore;
    isMiniBrowser?: boolean;
    adjustableLegsQuantity?: number;
}

const Preview3DContext = createContext<Preview3DContextProps>({});

export const use3DContext = () => useContext(Preview3DContext);

export const Preview3DComponent = ({
    product,
    productDataStore,
    isMiniBrowser,
    adjustableLegsQuantity,
    ...props
}: Preview3DComponentProps<ProductDataStore>) => {
    return (
        <Preview3DContext.Provider
            value={{
                values: product,
                productDataStore,
                isMiniBrowser,
                adjustableLegsQuantity,
            }}>
            <Preview3dComponentWithProvider {...props} />
        </Preview3DContext.Provider>
    );
};

export default Preview3DComponent;
