import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {Dimension, PreviewFormValues} from 'Preview3D/types';

export const toggleExtendedBifoldDoorHandler = (
    mesh: THREE.Group<THREE.Object3DEventMap>,
    meshPos: Dimension,
    variables: TemplateVariable,
    values: PreviewFormValues,
    exteriorThickness: number,
    carcaseThickness: number,
    isRightLShape = false,
    isLeftBifoldExtension = false
): AnimationHandler => {
    let initialPosition = {y: meshPos.y, x: meshPos.x};
    let openPosition = {
        y:
            -exteriorThickness -
            carcaseThickness +
            Number(values.cabinet_door_left),
        x:
            meshPos.x +
            (values.cabinet_left_width - values.cabinet_right_depth) -
            (exteriorThickness - carcaseThickness) -
            Number(values.cabinet_door_left),
    };
    let closePosition = {y: meshPos.y, x: meshPos.x};

    if (isRightLShape) {
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const size = new THREE.Vector3();
        boundingBox.getSize(size);

        const depth = size.x - exteriorThickness;

        initialPosition = {
            y:
                depth +
                Number(values.cabinet_door_left) +
                (exteriorThickness - carcaseThickness) -
                1,
            x: meshPos.x + exteriorThickness,
        };
        openPosition = {
            y:
                values.cabinet_left_width -
                values.cabinet_right_depth -
                (values.cabinet_right_width - values.cabinet_left_depth) +
                (exteriorThickness - carcaseThickness) +
                Number(values.cabinet_door_right),
            x:
                meshPos.x +
                (values.cabinet_right_width - values.cabinet_left_depth) +
                exteriorThickness +
                carcaseThickness -
                Number(values.cabinet_door_right),
        };
        closePosition = {
            y:
                depth +
                Number(values.cabinet_door_left) +
                (exteriorThickness - carcaseThickness) -
                1,
            x: meshPos.x + exteriorThickness,
        };

        if (variables?.is3DoorProduct && !isLeftBifoldExtension) {
            const thicknessDiff = exteriorThickness - carcaseThickness;

            initialPosition.y = closePosition.y =
                values.cabinet_left_width -
                values.cabinet_right_depth -
                carcaseThickness -
                thicknessDiff;
        }
    }

    const openDoor = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .delay(animationSpeed ? 100 : 0)
                .onUpdate(() => {
                    if (isRightLShape) mesh.position.x = initialPosition.x;
                    else mesh.position.y = initialPosition.y;
                });

            openTween.start();
        });

    const openDoorX = (animationSpeed = 700) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    if (isRightLShape) mesh.position.y = initialPosition.y;
                    else mesh.position.x = initialPosition.x;
                });

            openTween.start();
        });

    const closeTween = (animationSpeed = 750) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialPosition)
                .to(closePosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    if (isRightLShape) mesh.position.x = initialPosition.x;
                    else mesh.position.y = initialPosition.y;
                });

            tween.start();
        });

    const closeTweenX = (animationSpeed = 1200) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialPosition)
                .to(closePosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .delay(75)
                .onUpdate(() => {
                    if (isRightLShape) mesh.position.y = initialPosition.y;
                    else mesh.position.x = initialPosition.x;
                });

            tween.start();
        });

    let initialRotation = {y: (60 * Math.PI) / 180};
    let openRotation = {y: -((90 * Math.PI) / 180)};
    let closeRotation = {y: 0};

    if (isRightLShape) {
        initialRotation = {y: (60 * Math.PI) / 180};
        openRotation = {y: 0};
        closeRotation = {y: -((90 * Math.PI) / 180)};
    }

    if (isLeftBifoldExtension) {
        initialRotation = {y: (90 * Math.PI) / 180};
        openRotation = {y: -((60 * Math.PI) / 180)};
        closeRotation = {y: (90 * Math.PI) / 180};
    }

    const openDoor2 = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    resolve();
                })
                .delay(animationSpeed)
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        });

    const closeTween2 = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const tween = new TWEEN.Tween(initialRotation)
                .to(closeRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .delay(isLeftBifoldExtension ? 300 : 0)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            tween.start();
        });

    if (isLeftBifoldExtension) {
        return {
            open: [openDoor2],
            close: [closeTween2],
        };
    }

    return {
        open: [openDoor, openDoor2, openDoorX],
        close: [closeTween, closeTween2, closeTweenX],
    };
};
