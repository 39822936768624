import {HoleConfig, Operation} from 'Preview3D/types';
import * as THREE from 'three';

export const useHolePuncher = (
    operation: Operation,
    showTexture: boolean,
    group: THREE.Group,
    holeConfig: HoleConfig
) => {
    const {
        holeRadius,
        holeSpacing,
        holeOffset,
        backsideOffset,
        bottomHoleOffset,
        holeDepth,
    } = holeConfig;

    const panelHeight = Math.abs(
        operation.Segments[0]?.StartCoor.x - operation.Segments[1]?.EndCoor.x
    );
    const panelWidth = Math.abs(
        operation.Segments[0]?.StartCoor.y - operation.Segments[1]?.EndCoor.y
    );

    const secondRowOffset = panelWidth - bottomHoleOffset;

    const currentColor: THREE.ColorRepresentation = showTexture
        ? '#636e72'
        : '#00E000';

    // Adjust materials to blend with the environment
    const holeMaterial = new THREE.MeshPhysicalMaterial({
        color: currentColor,
        roughness: 0.5, // Smooth surface appearance
        metalness: 0.1, // Slight metallic effect for a clean finish
        clearcoat: 1.0, // Adds a polished clearcoat to smooth the edges
        clearcoatRoughness: 0.1, // Reduces reflections for a softer look
        flatShading: false, // Smooth shading for better blending
    });

    const tipMaterial = new THREE.MeshPhysicalMaterial({
        color: currentColor,
        roughness: 0.5,
        metalness: 0.1,
        clearcoat: 1.0,
        clearcoatRoughness: 0.1,
        flatShading: false,
    });

    for (let x = holeOffset; x <= panelHeight - holeOffset; x += holeSpacing) {
        const bottomHole = new THREE.CylinderGeometry(
            holeRadius,
            holeRadius,
            holeDepth,
            64 // Increased segments for smoother geometry
        );
        const bottomHoleMesh = new THREE.Mesh(bottomHole, holeMaterial);
        bottomHoleMesh.rotation.x = Math.PI / 2;
        bottomHoleMesh.position.set(x, backsideOffset, -holeDepth / 2);

        const bottomTip = new THREE.CylinderGeometry(
            holeRadius * 0.95,
            holeRadius * 0.95,
            0.1,
            64
        );
        const bottomTipMesh = new THREE.Mesh(bottomTip, tipMaterial);
        bottomTipMesh.rotation.x = Math.PI / 2;
        bottomTipMesh.position.set(x, backsideOffset, 0);

        const topHole = new THREE.CylinderGeometry(
            holeRadius,
            holeRadius,
            holeDepth,
            64
        );
        const topHoleMesh = new THREE.Mesh(topHole, holeMaterial);
        topHoleMesh.castShadow = true;
        topHoleMesh.rotation.x = Math.PI / 2;
        topHoleMesh.position.set(x, secondRowOffset, -holeDepth / 2);

        const topTip = new THREE.CylinderGeometry(
            holeRadius * 0.95,
            holeRadius * 0.95,
            0.1,
            64
        );
        const topTipMesh = new THREE.Mesh(topTip, tipMaterial);
        topTipMesh.rotation.x = Math.PI / 2;
        topTipMesh.position.set(x, secondRowOffset, 0);

        // Add all meshes to the group
        group.add(bottomHoleMesh);
        group.add(bottomTipMesh);
        group.add(topHoleMesh);
        group.add(topTipMesh);
    }
};
