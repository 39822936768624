import {useCallback} from 'react';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {
    getDefaultFormValues,
    getDefaultValues,
} from 'components/customer/Preview3DCommon/values/default';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

const useDefaultDynamicValues = () => {
    const {values} = use3DContext();
    const formValues = getDefaultFormValues(values);
    const {exteriorThickness, carcaseThickness} = useMaterialTexture();

    const generateDefaultValues = useCallback(() => {
        return getDefaultValues(
            {
                ...values,
                cabinet_carc_thickness: carcaseThickness,
                cabinet_ext_thickness: exteriorThickness,
            },
            formValues
        );
    }, [formValues, exteriorThickness, carcaseThickness]);

    return {
        generateDefaultValues,
    };
};

export default useDefaultDynamicValues;
