type Attribute = {value: object; name: string};
export type Node = {
    attributes: Attribute[];
    nodeName: string;
    textContent: string;
    children?: Node[];
};

// Reads element attributes and stores them as object keys
// eg: <Node a="1" b="2" c="3"/> -> {a:"1", b:"2", c:"3"}
export const readAttributes = (
    obj: Record<string, object>,
    node: Node,
    converter: (param: string) => number = null
) => {
    for (const attrib of node.attributes) {
        if (converter) {
            obj[attrib.name] = converter(
                attrib.value as unknown as string
            ) as unknown as object;
        } else {
            obj[attrib.name] = attrib.value;
        }
    }
    return obj;
};

// Reads element content as a float
// eg: <NodeName>5</NodeName> -> {NodeName:5}
export const readFloat = (obj: Record<string, number>, node: Node) => {
    obj[node.nodeName] = parseFloat(node.textContent);
    return obj;
};

// Reads element content as a string
// eg: <NodeName>5</NodeName> -> {NodeName:"5"}
export const readString = (obj: Record<string, string>, node: Node) => {
    obj[node.nodeName] = node.textContent;
    return obj;
};

// Reads element children as key/value pairs
// eg: <Node><Key>a</Key><Value>1</Value></Node> -> {a:"1"}
export const readKeyValue = (
    obj: Record<string, string>,
    node: Node,
    keyTag: string,
    valueTag: string
) => {
    let name = undefined;
    let value = undefined;

    for (const unitProp of node.children) {
        if (unitProp.nodeName === keyTag) name = unitProp.textContent;
        else if (unitProp.nodeName === valueTag) value = unitProp.textContent;
    }
    if (name !== undefined && value !== undefined) {
        obj[String(name)] = value;
    }
    return obj;
};

// Reads Unit type
// eg: <Node><Name>a</Name><Measure>1</Measure></Node> -> {a:"1"}
export const readUnit = (units: Record<string, string>, node: Node) => {
    return readKeyValue(units, node, 'Name', 'Measure');
};

// Reads Var type
// eg: <Node><Name>a</Name><Value>1</Value></Node> -> {a:"1"}
export const readVar = (units: Record<string, string>, node: Node) => {
    return readKeyValue(units, node, 'Name', 'Value');
};

// Reads Segment type
export const readSegment = (obj: Record<string, number>, node: Node) => {
    for (const segmentNode of node.children) {
        if (
            ['StartCoor', 'StartNormal', 'EndCoor', 'EndNormal'].includes(
                segmentNode.nodeName
            )
        ) {
            obj[segmentNode.nodeName] = readAttributes(
                {},
                segmentNode,
                parseFloat
            ) as unknown as number;
        } else if (
            [
                'StartOrgDisplacement',
                'EndOrgDisplacement',
                'OpenBaseCutSegment',
            ].includes(segmentNode.nodeName)
        ) {
            readFloat(obj, segmentNode);
        }
    }
    return obj;
};

// Reads Circle type
export const readCircle = (obj: Record<string, number>, node: Node) => {
    for (const circleNode of node.children) {
        if (['Center', 'Normal'].includes(circleNode.nodeName)) {
            obj[circleNode.nodeName] = readAttributes(
                {},
                circleNode,
                parseFloat
            ) as unknown as number;
        } else if (
            ['Diameter', 'OrgDisplacement'].includes(circleNode.nodeName)
        ) {
            readFloat(obj, circleNode);
        }
    }
    return obj;
};

// Reads Part type
export const readPart = (obj: Record<string, object>, node: Node) => {
    readAttributes(obj, node);
    for (const partNode of node.children) {
        if (
            ['Quantity', 'Width', 'Length', 'Thickness'].includes(
                partNode.nodeName
            )
        ) {
            readFloat(obj as unknown as Record<string, number>, partNode);
        } else {
            readString(obj as unknown as Record<string, string>, partNode);
        }
    }
    return obj;
};

// Reads Orientation type
export const readOrientation = (obj: Record<string, object>, node: Node) => {
    readAttributes(obj, node);
    for (const orientationNode of node.children) {
        readString(obj as unknown as Record<string, string>, orientationNode);
    }
    return obj;
};

// Reads Operations Group type
export const readOperationsGroup = (
    obj: Record<string, object>,
    node: Node
) => {
    readAttributes(obj, node);

    let operation: {
        Operations?: object[];
        Segments?: object[];
        Units?: Record<string, string>;
        Type: string;
        Circle?: object;
        Vars?: object[];
    } = null;

    for (const operationNode of node.children) {
        if (operationNode.nodeName === 'Type') {
            if (operation !== null) {
                if (obj.Operations === undefined) {
                    obj.Operations = [];
                }
                (obj.Operations as object[]).push(operation);
            }
            operation = {
                Type: operationNode.textContent,
            };
        } else if (operationNode.nodeName === 'Unit') {
            if (operation.Units === undefined) {
                operation.Units = {};
            }
            readUnit(operation.Units, operationNode);
        } else if (operationNode.nodeName === 'Segment') {
            if (operation.Segments === undefined) {
                operation.Segments = [];
            }
            operation.Segments.push(readSegment({}, operationNode));
        } else if (operationNode.nodeName === 'Circle') {
            operation.Circle = readCircle({}, operationNode);
        } else if (['Insert', 'Pitch'].includes(operationNode.nodeName)) {
            operation[operationNode.nodeName] = readAttributes(
                {},
                operationNode,
                parseFloat
            );
        } else if (
            ['ProgName', 'Comment', 'ToolName'].includes(operationNode.nodeName)
        ) {
            readString(
                operation as unknown as Record<string, string>,
                operationNode
            );
        } else if (
            ['Rotation', 'FeedSpeed', 'Repeat', 'ToolDiameter'].includes(
                operationNode.nodeName
            )
        ) {
            readFloat(
                operation as unknown as Record<string, number>,
                operationNode
            );
        } else if (operationNode.nodeName === 'Var') {
            if (operation.Vars === undefined) {
                operation.Vars = [];
            }
            operation.Vars.push(readVar({}, operationNode));
        }
    }

    if (operation !== null) {
        if (obj.Operations === undefined) {
            obj.Operations = [];
        }
        (obj.Operations as object[]).push(operation);
    }

    return obj;
};

// Reads Manufacturing type
export const readManufacturing = (obj: Record<string, object>, node: Node) => {
    readAttributes(obj, node);

    for (const manufacturingNode of node.children) {
        if (manufacturingNode.nodeName === 'Orientation') {
            if (obj.Orientations === undefined) {
                obj.Orientations = {};
            }
            const orientation = readOrientation({}, manufacturingNode);
            obj.Orientations[orientation.ID] = orientation;
        } else if (manufacturingNode.nodeName === 'OperationGroups') {
            if (obj.OperationGroups === undefined) {
                obj.OperationGroups = {};
            }
            const operationsGroup = readOperationsGroup({}, manufacturingNode);
            obj.OperationGroups[operationsGroup.ID] = operationsGroup;
        }
        if (manufacturingNode.nodeName === 'Label') {
        }
    }
    return obj;
};

// Reads Item type
export const readItem = (obj: Record<string, object>, node: Node) => {
    readAttributes(obj, node);
    for (const itemNode of node.children) {
        if (itemNode.nodeName === 'Part') {
            if (obj.Parts === undefined) {
                obj.Parts = {};
            }
            const part = readPart({}, itemNode);
            obj.Parts[part.ID] = part;
        }
    }
    return obj;
};

// Reads job type
export const readJob = (obj: Record<string, object>, node: Node) => {
    readAttributes(obj, node);

    for (const jobNode of node.children) {
        if (['Name', 'Source'].includes(jobNode.nodeName)) {
            readString(obj, jobNode);
        } else if (jobNode.nodeName === 'Unit') {
            if (obj.Units === undefined) {
                obj.Units = {};
            }
            readUnit(obj.Units, jobNode);
        } else if (jobNode.nodeName === 'Item') {
            if (obj.Items === undefined) {
                obj.Items = {};
            }
            const item = readItem({}, jobNode);
            obj.Items[item.ID] = item;
        } else if (jobNode.nodeName === 'Manufacturing') {
            if (obj.Manufacturing === undefined) {
                obj.Manufacturing = {};
            }
            const manufacturing = readManufacturing({}, jobNode);
            obj.Manufacturing[manufacturing.ID] = manufacturing;
        }
    }
    return obj;
};

// Reads batch type
export const readBatch = (obj: Record<string, object>, node: Node) => {
    readAttributes(obj, node);

    for (const nodeItem of node.children) {
        if (nodeItem.nodeName === 'Job') {
            if (obj.Jobs === undefined) {
                obj.Jobs = {};
            }
            const job = readJob({}, nodeItem);
            obj.Jobs[job.ID] = job;
        }
    }
    return obj;
};

export const loadDataXML = (rawTemplate: string): object => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(rawTemplate, 'text/xml');
    return readBatch({}, xmlDoc.children[0] as unknown as Node);
};
