import React, {useRef} from 'react';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {useProductContext} from 'contexts';
import {ConditionalRender} from 'Preview3D/types';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

const useAdjustableLegs = (
    scene: React.MutableRefObject<THREE.Scene>,
    isLeftReturn = false,
    hasLShapedShelves = false
) => {
    const adjustableLegModel =
        useRef<THREE.Group<THREE.Object3DEventMap | null>>(null);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {adjustableLegsQuantity} = useProductContext() as {
        adjustableLegsQuantity: number;
    };
    const {values} = use3DContext();

    const transformAdjustableLegsModel = (
        type:
            | 'BOTTOM_LEFT'
            | 'TOP_RIGHT'
            | 'TOP_LEFT'
            | 'BOTTOM_RIGHT'
            | 'FRONT'
            | 'BACK',
        model: THREE.Group<THREE.Object3DEventMap>
    ): THREE.Group<THREE.Object3DEventMap> => {
        const degreesX = 90;
        const rotationX = (degreesX * Math.PI) / 180;
        const degreesY = 180;
        const rotationY = (degreesY * Math.PI) / 180;
        const legsZOffset = -109;
        const modelScale = 750;
        const includeFaces =
            typeof values.include_drawer_faces !== 'undefined'
                ? Boolean(values.include_drawer_faces)
                : true;
        const voidWidth =
            values.cabinet_void_width &&
            Boolean(values.cabinet_cover_void) &&
            includeFaces
                ? parseFloat(values.cabinet_void_width?.toString())
                : 0;

        const multiplier = isLeftReturn ? -1 : 1;
        const widthForRightLegs = values.cabinet_width - voidWidth * multiplier;
        const widthForLeftLegs = values.cabinet_width + voidWidth * multiplier;
        const xPosition = (-(voidWidth / 2) - 33 * multiplier) * multiplier;

        model.scale.set(modelScale, modelScale, modelScale);
        model.position.z = legsZOffset;
        model.rotation.x = rotationX;

        switch (type) {
            case 'TOP_RIGHT':
                model.rotation.y = rotationY;
                model.position.x = widthForRightLegs / 2;
                model.position.y = -(values.cabinet_depth / 2) + 100;
                break;
            case 'TOP_LEFT':
                model.position.x = -(widthForLeftLegs / 2);
                model.position.y = -(values.cabinet_depth / 2) + 35;
                break;
            case 'BOTTOM_RIGHT':
                model.rotation.y = rotationY;
                model.position.x = widthForRightLegs / 2;
                model.position.y = values.cabinet_depth / 2 - 35;
                break;
            case 'BACK':
                model.position.x = voidWidth !== 0 ? xPosition : -33;
                model.position.y = values.cabinet_depth / 2 - 110;
                break;
            case 'FRONT':
                model.position.x = voidWidth !== 0 ? xPosition : -33;
                model.position.y = -(values.cabinet_depth / 2) + 35;
                break;
            default:
                model.position.x = -(widthForLeftLegs / 2);
                model.position.y = values.cabinet_depth / 2 - 100;
                break;
        }

        return model;
    };

    const addAdjustableLegs = (model: THREE.Group<THREE.Object3DEventMap>) => {
        if (values.cabinet_depth >= 250) {
            const bottomLeftModel = transformAdjustableLegsModel(
                'BOTTOM_LEFT',
                model.clone()
            );
            scene.current.add(bottomLeftModel);
        }

        const topRightModel = transformAdjustableLegsModel(
            'TOP_RIGHT',
            model.clone()
        );
        scene.current.add(topRightModel);

        const topLeftModel = transformAdjustableLegsModel(
            'TOP_LEFT',
            model.clone()
        );
        scene.current.add(topLeftModel);

        if (values.cabinet_depth >= 250) {
            const bottomRightModel = transformAdjustableLegsModel(
                'BOTTOM_RIGHT',
                model.clone()
            );
            scene.current.add(bottomRightModel);
        }

        if (adjustableLegsQuantity >= 5) {
            const frontModel = transformAdjustableLegsModel(
                'FRONT',
                model.clone()
            );
            scene.current.add(frontModel);
        }

        if (adjustableLegsQuantity >= 6 && values.cabinet_depth >= 250) {
            const backModel = transformAdjustableLegsModel(
                'BACK',
                model.clone()
            );
            scene.current.add(backModel);
        }
    };

    const transformLShapedAdjustableLegsModel = (
        type:
            | 'BOTTOM_LEFT'
            | 'TOP_RIGHT'
            | 'TOP_LEFT'
            | 'BOTTOM_RIGHT'
            | 'BOTTOM_RIGHT_EXT'
            | 'TOP_RIGHT_EXT'
            | 'TOP_LEFT_EXT',
        model: THREE.Group<THREE.Object3DEventMap>
    ): THREE.Group<THREE.Object3DEventMap> => {
        const degreesX = 90;
        const rotationX = (degreesX * Math.PI) / 180;
        const degreesY = 180;
        const rotationY = (degreesY * Math.PI) / 180;
        const legsZOffset = -109;
        const modelScale = 750;

        const cabinetRightWidth = Number(values.cabinet_right_width);
        const cabinetLeftWidth = Number(values.cabinet_left_width);
        const cabinetLeftDepth = Number(values.cabinet_left_depth);
        const cabinetRightDepth = Number(values.cabinet_right_depth);

        model.scale.set(modelScale, modelScale, modelScale);
        model.position.z = legsZOffset;
        model.rotation.x = rotationX;

        switch (type) {
            case 'TOP_RIGHT':
                model.rotation.y = rotationY;
                model.position.x =
                    -(cabinetRightWidth / 2) + cabinetLeftDepth - 35;
                model.position.y = cabinetLeftWidth / 2 - cabinetRightDepth;
                100;
                break;
            case 'TOP_LEFT':
                model.rotation.y = (90 * Math.PI) / 180;
                model.position.x =
                    -(cabinetRightWidth / 2) + cabinetLeftDepth - 35;
                model.position.y = -cabinetLeftWidth / 2 - 10;
                break;
            case 'BOTTOM_RIGHT':
                model.rotation.y = -(90 * Math.PI) / 180;
                model.position.x = -(cabinetRightWidth / 2) + 20;
                model.position.y = cabinetLeftWidth / 2 + 10;
                break;
            case 'BOTTOM_RIGHT_EXT':
                model.rotation.y = -(90 * Math.PI) / 180;
                model.position.x = cabinetRightWidth / 2 - 60;
                model.position.y = cabinetLeftWidth / 2 - 20;
                break;
            case 'TOP_RIGHT_EXT':
                model.rotation.y = (90 * Math.PI) / 180;
                model.position.x = cabinetRightWidth / 2 + 10;
                model.position.y =
                    cabinetLeftWidth / 2 - cabinetRightDepth + 25;
                break;
            case 'TOP_LEFT_EXT':
                model.rotation.y = (90 * Math.PI) / 180;
                model.position.x =
                    -(cabinetRightWidth / 2) + cabinetLeftDepth + 50;
                model.position.y =
                    cabinetLeftWidth / 2 - cabinetRightDepth + 25;
                break;
            default:
                model.rotation.y = (90 * Math.PI) / 180;
                model.position.x = -(cabinetRightWidth / 2) + 90;
                model.position.y = -cabinetLeftWidth / 2 - 10;
                break;
        }

        return model;
    };

    const addLShapedAdjustableLegs = (
        model: THREE.Group<THREE.Object3DEventMap>
    ) => {
        const bottomLeftModel = transformLShapedAdjustableLegsModel(
            'BOTTOM_LEFT',
            model.clone()
        );
        scene.current.add(bottomLeftModel);

        const topRightModel = transformLShapedAdjustableLegsModel(
            'TOP_RIGHT',
            model.clone()
        );
        scene.current.add(topRightModel);

        const topLeftModel = transformLShapedAdjustableLegsModel(
            'TOP_LEFT',
            model.clone()
        );
        scene.current.add(topLeftModel);

        const bottomRightModel = transformLShapedAdjustableLegsModel(
            'BOTTOM_RIGHT',
            model.clone()
        );
        scene.current.add(bottomRightModel);

        const bottomRightExtModel = transformLShapedAdjustableLegsModel(
            'BOTTOM_RIGHT_EXT',
            model.clone()
        );
        scene.current.add(bottomRightExtModel);

        const topRightExtModel = transformLShapedAdjustableLegsModel(
            'TOP_RIGHT_EXT',
            model.clone()
        );
        scene.current.add(topRightExtModel);

        const topLeftExtModel = transformLShapedAdjustableLegsModel(
            'TOP_LEFT_EXT',
            model.clone()
        );
        scene.current.add(topLeftExtModel);
    };

    const applyDynamicAdjustableLegs = () => {
        if (adjustableLegModel.current) {
            if (hasLShapedShelves)
                addLShapedAdjustableLegs(adjustableLegModel.current);
            else addAdjustableLegs(adjustableLegModel.current);
        } else {
            const loader = new GLTFLoader();

            loader.load(
                '/templates/3D/models/adjustable_leg.glb',
                function (gltf) {
                    const model = gltf.scene;
                    if (!Boolean(values.cabinet_include_hardware)) {
                        model.traverse(function (
                            child: THREE.Mesh & {
                                material: {
                                    transparent: boolean;
                                    opacity: number;
                                };
                            }
                        ) {
                            if (child.isMesh && child.material) {
                                child.material.transparent = true;
                                child.material.opacity = 0.1;
                            }
                        });
                    }

                    if (hasLShapedShelves) addLShapedAdjustableLegs(model);
                    else addAdjustableLegs(model);
                }
            );
        }
    };

    const adjustableLegsParts: ConditionalRender[] = [
        {
            condition: Boolean(Number(values.cabinet_adjustable_legs)),
            callback: applyDynamicAdjustableLegs,
        },
    ];

    return {
        adjustableLegsParts,
    };
};

export default useAdjustableLegs;
