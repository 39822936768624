import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {AnimationHandler} from 'components/customer/Preview3D/animation/entity/AnimationHandler';

export const toggleDoorHandler = (
    mesh: THREE.Group<THREE.Object3DEventMap>,
    variables: TemplateVariable,
    isRightDoor = false,
    isPairDoor = false,
    isTopHangDoor = false,
    degrees = 120
): AnimationHandler => {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    const width = size.x;

    if (isRightDoor) {
        if (isPairDoor) {
            mesh.position.x = width;
            mesh.scale.x = -1;
        }
    } else {
        mesh.position.x = -width;
        mesh.scale.x = -1;

        if (isTopHangDoor) {
            mesh.position.y = size.y;
            mesh.scale.y = -1;
        }
    }

    const radians = (degrees * Math.PI) / 180;

    const initialRotation = isTopHangDoor ? {x: (90 * Math.PI) / 180} : {y: 0};
    const openRotation = isTopHangDoor
        ? {x: -(17 * Math.PI) / 180}
        : {y: isRightDoor ? radians : -radians};
    const closeRotation = isTopHangDoor ? {x: (90 * Math.PI) / 180} : {y: 0};

    const openDrawer = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .delay(
                    variables?.is3DoorProduct && animationSpeed !== 0 ? 300 : 0
                )
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    if (isTopHangDoor) mesh.rotation.x = initialRotation.x;
                    else mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        });

    const closeTween = (animationSpeed = 1000) =>
        new Promise<void>((resolve) => {
            const closeTween = new TWEEN.Tween(initialRotation)
                .to(closeRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .delay(variables?.is3DoorProduct ? 300 : 0)
                .onComplete(() => {
                    resolve();
                })
                .onUpdate(() => {
                    if (isTopHangDoor) mesh.rotation.x = initialRotation.x;
                    else mesh.rotation.y = initialRotation.y;
                });

            closeTween.start();
        });

    return {
        open: [openDrawer],
        close: [closeTween],
    };
};
